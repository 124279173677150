import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData } from '../../helpers/fackBackend_Helper';



function* getProfileDataResponse() {
    try {
        const response = yield call(getList, '/getUserByToken');
        if (response.response.Status) {
            yield put(
                actions.getProfileDataSuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getProfileDataFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getProfileDataFailed(error));
    }
}

function* changePasswordResponse({ payload: { passwordData } }) {
    try {
        const response = yield call(addData, '/changePassword', passwordData);
        if (response.response.Status) {
            yield put(actions.changePasswordSuccess(
                response.response.Message
            ));
        }
        else {
            yield put(actions.changePasswordFailed(
                response.response.Message
            ));
        }
    } catch (error) {
        yield put(actions.changePasswordFailed(error));
    }
}

function* editProfileDataResponse({ payload: { Id, data } }) {
    try {
        const response = yield call(addData, '/updateCustomer/' + Id, data);
        if (response.response.Status) {
            const responseList = yield call(getList, '/getUserByToken');
            if (responseList.response.Status) {
                yield put(
                    actions.editProfileDataSuccess(
                        response.response.Message, responseList.response.Data
                    ));
            } else {
                yield put(actions.editProfileDataFailed(
                    response.response.Message
                ));
            }
        } else {
            yield put(actions.editProfileDataFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.editProfileDataFailed(error));
    }
}

function* getOrderDataResponse() {
    try {
        const response = yield call(getList, '/orderForCustomer');
        if (response.response.Status) {
            yield put(
                actions.getOrderDataSuccess(
                    response.response.Data
                ));
        } else {
            yield put(actions.getOrderDataFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getOrderDataFailed(error));
    }
}

function* customerAccountDeleteResponse() {
    try {
        const response = yield call(getList, '/customerAccountDelete');
        if (response.response.Status) {
            // localStorage.removeItem("token");
            yield put(
                actions.customerAccountDeleteSuccessfully(
                    response.response.Message
                ));
            // history.push('/');
        } else {
            yield put(actions.customerAccountDeleteFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.customerAccountDeleteFailed(error));
    }
}

function* getEsimDataResponse() {
    try {
        const response = yield call(getList, '/getCustomerOrderDetails');
        if (response.response.Status) {
            yield put(
                actions.getEsimSuccess(
                    response.response.Orders
                ));
        } else {
            yield put(actions.getEsimFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getEsimFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_POFILE_DATA, getProfileDataResponse)]);
    yield all([takeEvery(actions.CHANGE_PASSWORD, changePasswordResponse)]);
    yield all([takeEvery(actions.EDIT_PROFILE_DATA, editProfileDataResponse)]);
    yield all([takeEvery(actions.GET_ORDER_DATA, getOrderDataResponse)]);
    yield all([takeEvery(actions.DELETE_CUSTOMER_ACCOUNT, customerAccountDeleteResponse)]);
    yield all([takeEvery(actions.GET_ESIM, getEsimDataResponse)]);
}