const actions = {
    GET_POFILE_DATA: "GET_POFILE_DATA",
    GET_POFILE_DATA_SUCCESS: "GET_POFILE_DATA_SUCCESS",
    GET_POFILE_DATA_FAILED: "GET_POFILE_DATA_FAILED",
    EDIT_PROFILE_DATA: "EDIT_PROFILE_DATA",
    EDIT_PROFILE_DATA_SUCCESS: "EDIT_PROFILE_DATA_SUCCESS",
    EDIT_PROFILE_DATA_FAILED: "EDIT_PROFILE_DATA_FAILED",
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAILED: "CHANGE_PASSWORD_FAILED",
    GET_ORDER_DATA: "GET_ORDER_DATA",
    GET_ORDER_DATA_SUCCESS: "GET_ORDER_DATA_SUCCESS",
    GET_ORDER_DATA_FAILED: "GET_ORDER_DATA_FAILED",
    RESET_PROFILE: "RESET_PROFILE",
    DELETE_CUSTOMER_ACCOUNT: "DELETE_CUSTOMER_ACCOUNT",
    DELETE_CUSTOMER_ACCOUNT_SUCCESSFULLY: "DELETE_CUSTOMER_ACCOUNT_SUCCESSFULLY",
    DELETE_CUSTOMER_ACCOUNT_FAILED: "DELETE_CUSTOMER_ACCOUNT_FAILED",
    GET_ESIM: "GET_ESIM",
    GET_ESIM_SUCCESS: "GET_ESIM_SUCCESS",
    GET_ESIM_FAILED: "GET_ESIM_FAILED",

    getProfileData: () => ({
        type: actions.GET_POFILE_DATA,
    }),
    getProfileDataSuccess: (profileDataResult) => ({
        type: actions.GET_POFILE_DATA_SUCCESS,
        profileDataResult
    }),
    getProfileDataFailed: (profileError) => ({
        type: actions.GET_POFILE_DATA_FAILED,
        profileError
    }),
    editProfileData: (Id, data) => ({
        type: actions.EDIT_PROFILE_DATA,
        payload: { Id, data }
    }),
    editProfileDataSuccess: (profileMessage, profileDataResult) => ({
        type: actions.EDIT_PROFILE_DATA_SUCCESS,
        profileMessage,
        profileDataResult

    }),
    editProfileDataFailed: (profileError) => ({
        type: actions.EDIT_PROFILE_DATA_FAILED,
        profileError,
    }),
    changePassword: (passwordData) => ({
        type: actions.CHANGE_PASSWORD,
        payload: { passwordData },
    }),
    changePasswordSuccess: (passwordMessage) => ({
        type: actions.CHANGE_PASSWORD_SUCCESS,
        passwordMessage
    }),
    changePasswordFailed: (passwordError) => ({
        type: actions.CHANGE_PASSWORD_FAILED,
        passwordError
    }),
    getOrderData: () => ({
        type: actions.GET_ORDER_DATA,
    }),
    getOrderDataSuccess: (orderDataResult) => ({
        type: actions.GET_ORDER_DATA_SUCCESS,
        orderDataResult
    }),
    getOrderDataFailed: (Error) => ({
        type: actions.GET_ORDER_DATA_FAILED,
        Error
    }),
    resetProfile: () => ({
        type: actions.RESET_PROFILE,
    }),
    customerAccountDelete: () => ({
        type: actions.DELETE_CUSTOMER_ACCOUNT,
    }),
    customerAccountDeleteSuccessfully: (message) => ({
        type: actions.DELETE_CUSTOMER_ACCOUNT_SUCCESSFULLY,
        message
    }),
    customerAccountDeleteFailed: (error) => ({
        type: actions.DELETE_CUSTOMER_ACCOUNT_FAILED,
        error
    }),
    getEsim: () => ({
        type: actions.GET_ESIM,
    }),
    getEsimSuccess: (esimDataResult) => ({
        type: actions.GET_ESIM_SUCCESS,
        esimDataResult
    }),
    getEsimFailed: (profileError) => ({
        type: actions.GET_ESIM_FAILED,
        profileError
    }),
}
export default actions;