import React from 'react';
import { Row, Col, Image, Tag } from 'antd';
import { Comment } from '@ant-design/compatible';
import moment from 'moment';
import './OrderModelComponent.css';
import noImageFound from '../../assets/image/no-image-found.png';


const OrderModelComponent = ({ order }) => {

    return (
        <div className="modal fade" id={`orderDetailModal`} data-bs-backdrop='static'>
            <div className="modal-dialog modal-lg modal-sm">
                <div className="modal-content animate order-view-modal">
                    <div className="modal-header">
                        <h6 className='text-white' style={{ margin: 'auto 0' }}>{'Order # : ' + order.OrderNumber}</h6>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <div className="modal-body">
                        <Row>
                            <Col span={8}>
                                <Comment
                                    author={<a>ORDER DATE</a>}
                                    content={<p>{moment(new Date(order.OrderDate)).format("MM/DD/YYYY")}</p>}
                                />
                            </Col>
                            <Col span={8}>
                                <Comment
                                    author={<a>GRAND TOTAL</a>}
                                    content={<p>$ {order.GrandTotal}</p>}
                                />
                            </Col>
                            <Col span={8}>
                                <Comment
                                    author={<a>COUPON DISCOUNT</a>}
                                    content={<p>{order.CouponDiscount}</p>}
                                />
                            </Col>
                            <Col span={8}>
                                <Comment
                                    author={<a>NET AMOUNT</a>}
                                    content={<p>$ {order.NetAmount}</p>}
                                />
                            </Col>
                            <Col span={8}>
                                <Comment
                                    author={<a>PAYMENT ID</a>}
                                    content={<p>{order.PaymentTxnID == '' || order.PaymentTxnID == null ? 'Not available' : order.PaymentTxnID}</p>}
                                />
                            </Col>
                            <Col span={8}>
                                <Comment
                                    author={<a>STATUS</a>}
                                    content={order.ErrorCode == null || order.ErrorCode == "" ? <Tag color={order.OrderStatus === "Complete" ? "success" : order.OrderStatus === "Failed" ? "error" : "warning"} className="draft">{order.OrderStatus}</Tag> : <Tag color="default" className="draft">Payment Cancelled</Tag>}
                                />
                            </Col>
                        </Row>

                        <hr style={styles.hr} />
                        <h6>Plan Details</h6>
                        <hr style={styles.hr} />

                        <Row>
                            <Col span={12}>
                                <Row>
                                    <Col span={24}>
                                        <Comment
                                            author={<a>PLAN NAME</a>}
                                            content={<p>{order.OrderDetailsData ? order.OrderDetailsData.PlanName : ''}</p>}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <Comment
                                            author={<a>DATA</a>}
                                            content={<p>{order.OrderDetailsData ? order.OrderDetailsData.PlanData : ''}</p>}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <Comment
                                            author={<a>VALIDITY</a>}
                                            content={<p>{order.OrderDetailsData ? order.OrderDetailsData.PlanDays : ''}</p>}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <Comment
                                            author={<a>PLAN PRICE</a>}
                                            content={<p>$ {order.GrandTotal}</p>}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={12}>
                                <Image preview={false} width={300} fallback={noImageFound} src={process.env.REACT_APP_BASEURL + `/planimage/${order.OrderDetailsData ? order.OrderDetailsData.ImageUrl : ''}`} />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}

const styles = {
    hr: {
        border: '1px solid'
    }
}

export default OrderModelComponent