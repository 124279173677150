import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Form, Input, Button } from "antd";
import logo from '../../assets/image/virtuzo-logo.png';
import resetPasswordImg from '../../assets/image/reset_password.png';
import authActions from '../../redux/auth/actions';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import Loading from '../Loader/Loading';

function ResetPassword() {
    const dispatch = useDispatch();
    const { resetForgetPassword, resetAuthError } = authActions;
    const baseURL = process.env.REACT_APP_WEBSITE_BASE_URL;
    const [form] = Form.useForm();
    const successMessage = useSelector((state) => state.authReducer.message);
    const errorMessage = useSelector((state) => state.authReducer.error);
    const loading = useSelector((state) => state.authReducer.loading);
    const [resetPasswordData, setResetPasswordData] = useState({
        customerId: null,
        token: "",
        newPassword: ""
    })


    useEffect(() => {
        const url = window.location.href;
        const parts = url.split('/');
        const userId = parts[parts.length - 2];
        const tokenData = parts[parts.length - 1];
        setResetPasswordData(prevState => ({
            ...prevState,
            customerId: userId,
            token: tokenData
        }));
    }, [])

    const handleChange = (e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;
        setResetPasswordData(prevState => ({
            ...prevState,
            [fname]: fvalue,
        }));
    }

    const handleResetPassword = () => {
        dispatch(resetForgetPassword(resetPasswordData))
    }

    const handleClose = () => {
        dispatch(resetAuthError());
    }

    const error = (text) => {
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: `${text}`,
            // text: `${text}`,
            showConfirmButton: true,
            confirmButtonText: 'OK',
            customClass: {
                popup: 'custom-popup-class',
                title: 'custom-title-class',
                confirmButton: 'custom-button-class',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                // Perform your desired action here when the user clicks "OK"
                handleClose();
                // Add your code here...
            }
        });
    };

    const success = (text) => {
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: `${text}`,
            text: `You can log in with your new password now.`,
            showConfirmButton: true,
            confirmButtonText: 'LOG IN',
            customClass: {
                popup: 'custom-popup-class',
                title: 'custom-title-class',
                confirmButton: 'custom-button-class',
            },
        }).then((result) => {
            // handleClose();
            if (result.isConfirmed) {
                window.location = `${baseURL}/login`;
                handleClose();
                if (successMessage !== null && successMessage !== undefined) {
                    form.resetFields();
                }
            }
        });
    };

    return (
        <section className="login-sec-1 login-bg">
            {successMessage !== null && successMessage !== undefined && success(successMessage)}
            {errorMessage !== null && errorMessage !== undefined && error(errorMessage)}
            {loading && <Loading />}
            <div className="container">
                <div className="row d-flex align-item-center justify-content-center py-2 px-3">
                    <div className="col-lg-4 form-tab-1 py-3 ">
                        <Link to="/"> <img src={logo} width="250px" className="resetPasswordPadding mb-4" id='logo-fold-size'></img></Link>
                        {/* <h2 className="py-3"><a href="index.html">logo</a></h2> */}
                        <div className="tab-content  d-flex align-item-center justify-content-center" id="pills-tabContent1">
                            <div className="tab-pane fade active show" id="pills-home1" role="tabpanel" aria-labelledby="pills-home-tab1">
                                <div className="login-title">
                                    <h4>Reset Password</h4>
                                    {/* <h2>eSIM Mapping</h2> */}
                                    <p className="para-sign">Please enter your new password down below and retype it to reset your current password.</p>
                                </div>
                                <div>
                                    <img src={resetPasswordImg} width={250} height='auto' alt="" className="resetPasswordImg" />
                                </div>
                                <Form form={form} className="row-col" size="large" onFinish={handleResetPassword}>
                                    <Form.Item className="username" name="newPassword" rules={[{ required: true, message: 'Please input your password!', },]} hasFeedback>
                                        <Input.Password placeholder="Create Password" name="newPassword" onChange={handleChange} />
                                    </Form.Item>

                                    <Form.Item name="confirmPassword" dependencies={['newPassword']} hasFeedback rules={[{ required: true, message: 'Please confirm your password!', },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('newPassword') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The Confirm passwords does not match with entered password!'));
                                        },
                                    }),]}>
                                        <Input.Password name="confirmPassword" placeholder="Confirm Password" />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" className='login-btn' htmlType="submit" style={{ width: "100%" }} > RESET PASSWORD </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default ResetPassword