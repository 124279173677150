import React,{useEffect} from 'react'

const RefundPolicies = () => {
  let currentDate = new Date().toUTCString().slice(5, 16);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <div>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-12">
            <h2 className='mb-4'>Refund Policy</h2>
            <p>Thank you for choosing our website for purchasing eSIM services. We want to ensure your satisfaction with your purchase. This Refund Policy outlines the terms and conditions for requesting a refund for eSIM services purchased through our website. Please read this policy carefully before making a purchase.</p>
            <h6><strong>Eligibility for Refunds</strong></h6>
            <p>a. Refunds are eligible only for eSIM services purchased through our website.</p>
            <p>b. To be eligible for a refund, you must request the refund within 24 hours from the time of purchase.</p>
            <p>c. Refunds are available for unused eSIM profiles that have not been activated or provisioned.</p>
            <h6><strong>Non-Refundable Items</strong></h6>
            <p> a. Activation fees, shipping fees, or any other non-recurring charges are non-refundable.</p>
            <p>b. Refunds are not available for eSIM profiles that have been activated or provisioned.</p>
            <p>c. Refunds are not available for eSIM profiles that have passed the specified refund request period.</p>
            <h6><strong>Refund Process</strong></h6>
            <p>a. To request a refund, you must contact our customer support team at +1 (713) 280-7976 and provide the necessary details, including your order number and reason for the refund request.</p>
            <p>b. Our customer support team will review your request and determine your eligibility for a refund.</p>
            <p>c. If your refund request is approved, we will process the refund to the original payment method used for the purchase within a reasonable timeframe.</p>
            <h6><strong>Disputed Charges</strong></h6>
            <p>a. If you believe there has been an unauthorized or fraudulent charge on your account, please contact our customer support team immediately to resolve the issue.</p>
            <p>b. We may require additional information or documentation to investigate and resolve disputed charges.</p>
            <h6><strong>Changes to Refund Policy</strong></h6>
            <p>a. We reserve the right to modify or update this Refund Policy at any time without prior notice.</p>
            <p>b. Any changes to the Refund Policy will be effective immediately upon posting on our website.</p>
            <p>c. It is your responsibility to review the Refund Policy periodically for any updates or changes.</p>
            <p>
              Please note that this Refund Policy is in addition to any rights and remedies you may have under applicable consumer protection laws.<br />
              If you have any questions or need further assistance regarding our Refund Policy, please contact our customer support team at +1 (713) 280-7976.<br />
              This Refund Policy was last updated on {currentDate}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RefundPolicies
