import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getList } from '../../helpers/fackBackend_Helper';

function* getRegionListResponse() {
    try {
        const response = yield call(getList, '/webregion');
        if (response.response.Status) {
            yield put(
                actions.getRegionSuccess(
                    response.response.Data
                )
            );
        } else {
            yield put(actions.getRegionFailed());
        }
    } catch (error) {
        yield put(actions.getRegionFailed());
    }
}


export default function* rootSaga() {
    yield all([takeEvery(actions.GET_REGION, getRegionListResponse)]);
}