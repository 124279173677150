import React from 'react';
import './loading.css';

function Loading() {
  return (
    <div className="row">
      <div className="col loader-main">
        <div className="loader">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
        </div>
      </div>
    </div>
  )
}

export default Loading