const actions = {
    CREATE_ORDER_REQUEST: "CREATE_ORDER_REQUEST",
    CREATE_ORDER_REQUEST_SUCCESS: "CREATE_ORDER_REQUEST_SUCCESS",
    CREATE_ORDER_REQUEST_FAILED: "CREATE_ORDER_REQUEST_FAILED",
    SEND_ORDER_DATA_REQUEST: "SEND_ORDER_DATA_REQUEST",
    SEND_ORDER_DATA_REQUEST_SUCCESS: "SEND_ORDER_DATA_REQUEST_SUCCESS",
    SEND_ORDER_DATA_REQUEST_FAILED: "SEND_ORDER_DATA_REQUEST_FAILED",
    RESET_CREATE_ORDER_REQUEST: "RESET_CREATE_ORDER_REQUEST",
    GET_PAYMENT_GATEWAY: "GET_PAYMENT_GATEWAY",
    GET_PAYMENT_GATEWAY_SUCCESS: "GET_PAYMENT_GATEWAY_SUCCESS",
    GET_PAYMENT_GATEWAY_FAILED: "GET_PAYMENT_GATEWAY_FAILED",
    COUPON_APPLY: "COUPON_APPLY",
    COUPON_APPLY_SUCCESS: "COUPON_APPLY_SUCCESS",
    COUPON_APPLY_FAILED: "COUPON_APPLY_FAILED",
    COUPON_RESET: "COUPON_RESET",
    COUPON_RESET_MESSAGE:'COUPON_RESET_MESSAGE',

    
    createOrderRequest: (data) => ({
        type: actions.CREATE_ORDER_REQUEST,
        payload: { data }
    }),
    createOrderRequestSuccess: (OrderId, orderMessage,OrderNumber) => ({
        type: actions.CREATE_ORDER_REQUEST_SUCCESS,
        OrderId,
        orderMessage,
        OrderNumber
    }),
    createOrderRequestFailed: (orderError) => ({
        type: actions.CREATE_ORDER_REQUEST_FAILED,
        orderError
    }),
    sendOrderDataRequest: (data) => ({
        type: actions.SEND_ORDER_DATA_REQUEST,
        payload: { data }
    }),
    sendOrderDataRequestSuccess: (orderData) => ({
        type: actions.SEND_ORDER_DATA_REQUEST_SUCCESS,
        orderData,
    }),
    sendOrderDataRequestFailed: ( orderData) => ({
        type: actions.SEND_ORDER_DATA_REQUEST_FAILED,
    
        orderData
    }),
    resetcreateOrderRequest: () => ({
        type: actions.RESET_CREATE_ORDER_REQUEST,
    }),

    getPaymentGateway: () => ({
        type: actions.GET_PAYMENT_GATEWAY,
    }),
    getPaymentGatewaySuccess: (paymentGateway) => ({
        type: actions.GET_PAYMENT_GATEWAY_SUCCESS,
        paymentGateway,
    }),
    getPaymentGatewayFailed: (error) => ({
        type: actions.GET_PAYMENT_GATEWAY_FAILED,
        error
    }),
    applyCoupon: (data) => ({
        type: actions.COUPON_APPLY,
        payload: { data }
    }),
    applyCouponSuccess: (message, result) => ({
        type: actions.COUPON_APPLY_SUCCESS,
        message, result
    }),
    applyCouponFailed: (error) => ({
        type: actions.COUPON_APPLY_FAILED,
        error
    }),

    resetCoupon: () => ({
        type: actions.COUPON_RESET,
    }),
    resetCouponMessage: () => ({
        type: actions.COUPON_RESET_MESSAGE,
    }),
}
export default actions;