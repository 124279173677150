import React from 'react';
import { Form, Input, Button } from "antd";
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import logo from '../../assets/image/virtuzo-logo.png';
import authActions from '../../redux/auth/actions';
import forgetPasswordImg from '../../assets/image/forgetPassword.png';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
import Loading from '../Loader/Loading';

function Forget() {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { verifyEmail, resetAuthError } = authActions;
    const successMessage = useSelector((state) => state.authReducer.message);
    const errorMessage = useSelector((state) => state.authReducer.error);
    const loading = useSelector((state) => state.authReducer.loading);

    const handleForgetPassword = (values) => {
        dispatch(verifyEmail(values));
    }

    const handleClose = () => {
        dispatch(resetAuthError());
    }

    const error = (text) => {
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: `${text}`,
            // text: `${text}`,
            showConfirmButton: true,
            confirmButtonText: 'OK',
            customClass: {
                popup: 'custom-popup-class',
                title: 'custom-title-class',
                confirmButton: 'custom-button-class',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                // Perform your desired action here when the user clicks "OK"
                handleClose();
                // Add your code here...
            }
        });
    };
    const success = (text) => {
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Your link has been successfully sent',
            text: `${text}`,
            showConfirmButton: true,
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> OK, GOT IT!',
            confirmButtonAriaLabel: 'Thumbs up, OK, GOT IT!',
            customClass: {
                popup: 'custom-popup-class',
                title: 'custom-title-class',
                confirmButton: 'custom-button-class',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                // Perform your desired action here when the user clicks "OK"
                // Perform your desired action here when the user clicks "OK"
                handleClose();
                // Add your code here...
                if (successMessage !== null && successMessage !== undefined) {
                    form.resetFields();
                    // closeButtonRef.current.click();
                }
                // handleClose();

            }
        });
    };

    return (
        <section className="login-sec-1 login-bg">
            {loading && <Loading />}
            {successMessage !== null && successMessage !== undefined && success(successMessage)}
            {errorMessage !== null && errorMessage !== undefined && error(errorMessage)}
            <div className="container">
                <div className="row d-flex align-item-center justify-content-center py-2 px-3">
                    <div className="col-lg-4 form-tab-1 py-3 ">
                        <Link to="/"><img src={logo} width="250px" className="resetPasswordPadding mb-4" id='logo-fold-size'></img></Link>
                        {/* <h2 className="py-3"><a href="index.html">logo</a></h2> */}
                        <div className="tab-content  d-flex align-item-center justify-content-center" id="pills-tabContent1">
                            <div className="tab-pane fade active show" id="pills-home1" role="tabpanel" aria-labelledby="pills-home-tab1">
                                <div className="login-title">
                                    <h4>Forget Password</h4>
                                    {/* <h2>eSIM Mapping</h2> */}
                                    <p className="para-sign">You can reset your password by entering your email down below. We'll then send you an email with a link to reset your password.</p>
                                </div>
                                <div style={{ marginTop: "-45px" }}>
                                    <img src={forgetPasswordImg} width={300} height='auto' alt="" className="forgetPasswordImg" />
                                </div>
                                <Form form={form} className="row-col" size="large" onFinish={handleForgetPassword}>
                                    <Form.Item className="username" name="email" rules={[{ required: true, message: "Please input your email!", }, { type: "email", message: "Please input a valid email!" }]}>
                                        <Input placeholder="Email Address" name="email" autoComplete='off' />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" className='login-btn' htmlType="submit" style={{ width: "100%" }} > CONTINUE </Button>
                                        {/* <Button type="primary" className='login-btn mt-3'>Back to Login</Button> */}
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Forget