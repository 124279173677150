import React, { useState, useEffect } from 'react'
import noImageFound from '../../assets/image/no-image-found.png';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import regionActions from '../../redux/region/actions';
import Carousel from 'react-grid-carousel'
import CountryFlag from 'react-country-flag';
import { Input, Image } from "antd";
import homeActions from '../../redux/home/actions';
import Loading from '../Loader/Loading';
import noPlanFoundImg from '../../assets/image/no-data-found.png';
const { getRegion } = regionActions;
const countryList = require('country-list');

function Shop() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { getSearchPlan, getPlanData, getPlanDataBestSeller } = homeActions
    const [showSearchDropdown, setShowSearchDropdown] = useState(false);
    const [countSum, setCountSum] = useState(32);
    const [activeDiv, setActiveDiv] = useState(null);
    const [searchIconsShow, setSearchIconsShow] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const { searchRegionResult, searchCountryResult, planDataResult } = useSelector((state) => state.homeReducer);
    const searchLoading = useSelector((state) => state.homeReducer.searchLoading);
    const regionResult = useSelector((state) => state.regionReducer.regionResult);
    const [plansData, setPlansData] = useState([]);
    const [plansDataLength, setPlansDataLength] = useState(16);
    const [dummyData, setDummyData] = useState([1, 2, 3, 4, 5, 6, 7, 8]);
    const [searchPlanMobileView, setSearchPlanMobileView] = useState(false)
    const currentDateTime = new Date();
    const millisecondsSinceEpoch = currentDateTime.getTime();
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getRegion());
        let filterData = {
            availableForFlag: "",
            availableForID: "",
            limit: "16",
            token: localStorage.getItem('token') !== null ? localStorage.getItem('token') : null
        }
        dispatch(getPlanData(filterData));
    }, []);

    useEffect(() => {
        const filterData = planDataResult.filter(item => (item.PackageTypeID > 0 && item.PackageTypeID != null && item.DateLatestAvailable >= millisecondsSinceEpoch) || (item.MayaPlanUID != null && item.DateLatestAvailable >= millisecondsSinceEpoch) || item.ESimGoTrafficPolicyID != '');
        setPlansData(filterData);
        setPlansDataLength(filterData.length)
    }, [planDataResult])

    const handleSearchPlan = (e) => {
        const fvalue = e.target.value;
        setSearchValue(fvalue);
        if (fvalue.length > 0) {
            setSearchIconsShow(false);
        } else {
            setSearchIconsShow(true);
        }
        if (fvalue.length >= 3) {
            let filter = {
                search: fvalue
            }
            setSearchPlanMobileView(true)
            setShowSearchDropdown(true);
            dispatch(getSearchPlan(filter));
        } else {
            setShowSearchDropdown(false);
            setSearchPlanMobileView(true)
            let filterData = {
                availableForFlag: "",
                availableForID: "",
                limit: "20",
                token: localStorage.getItem('token') !== null ? localStorage.getItem('token') : null
            }
            dispatch(getPlanData(filterData));
        }
    }
    const clearSearch = () => {
        setSearchValue('');
        setSearchIconsShow(true);
        setShowSearchDropdown(false);
        setSearchPlanMobileView(false)
        let filterData = {
            availableForFlag: "",
            availableForID: "",
            limit: "6",
            token: localStorage.getItem('token') !== null ? localStorage.getItem('token') : null
        }
        dispatch(getPlanData(filterData));
    }

    const handleSearchPlanByFlag = (availableFlag, flagId) => {
        let filterData = {
            availableForFlag: availableFlag,
            availableForID: flagId,
            limit: "",
            token: localStorage.getItem('token') !== null ? localStorage.getItem('token') : null
        }
        dispatch(getPlanData(filterData));
        setShowSearchDropdown(false);
    }

    const navigateToPlanDetailPage = (referenceURL) => {
        history.push(`/plan-details/${referenceURL}`);
    }

    const handleMoreProduct = (num) => {
        setCountSum(countSum + num)
        let filterData = {
            availableForFlag: "",
            availableForID: "",
            limit: countSum,
            token: localStorage.getItem('token') !== null ? localStorage.getItem('token') : null
        }
        dispatch(getPlanData(filterData));

    }

    const handleAllPlan = () => {
        let filterData = {
            availableForFlag: "",
            availableForID: "",
            limit: "16",
            token: localStorage.getItem('token') !== null ? localStorage.getItem('token') : null
        }
        dispatch(getPlanData(filterData));
    }

    return (
        <>
            {/* section 1 */}
            {/* {searchLoading && <Loading />} */}
            <section className="sec-shop-2">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-8 col-sm-12 py-5">
                            <h3 className="searching-heading">Search an international destination</h3>
                            <p className="para-1">Find and buy the best prepaid eSIMs online for your travels. Connect to the internet in minutes in more than 160 destinations around the  world.</p>
                            <div className="search-box mt-4">
                                <Input placeholder="Search Data Pack over 200+ countries and region" autoComplete='off' name="search" className="search-input box-inset" value={searchValue} onChange={(e) => handleSearchPlan(e)} />
                                {searchIconsShow === true ? <button className="search-btn"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg></button> : <button className="search-btn" onClick={clearSearch}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                </svg></button>}
                                {
                                    showSearchDropdown && <div className="autocomplete-suggestions autocomplete-responsive" >
                                        {searchCountryResult && searchCountryResult.length > 0 &&
                                            <div className="countries-search-segment segment-country"><p>Country</p></div>
                                        }
                                        {searchCountryResult && searchCountryResult.length > 0 && searchCountryResult.map((data, index) => {
                                            return (<div key={index} className="autocomplete-suggestion" data-index="147" onClick={() => handleSearchPlanByFlag("18", data.ID)}><CountryFlag countryCode={data.ISO2} svg style={{ width: '39px', height: '41px', borderRadius: "9px", margin: "-6px 0 0 -8px" }} />&nbsp;&nbsp;{data.CountryName}</div>)
                                        })}
                                        {searchRegionResult && searchRegionResult.length > 0 &&
                                            <div className="countries-search-segment segment-region"><p>Region</p></div>
                                        }
                                        {searchRegionResult && searchRegionResult.length > 0 && searchRegionResult.map((data, index) => {
                                            return (<div key={index} className="autocomplete-suggestion" data-index="147" onClick={() => handleSearchPlanByFlag("19", data.ID)}>{data.Name}</div>)
                                        })}
                                        <div className="countries-search-segment segment-global"><p>Global</p></div>
                                        <div className="autocomplete-suggestion" data-index="147" onClick={() => handleSearchPlanByFlag("20", "")}>Discover Global</div>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* section 1 */}

            {/* section 2 */}
            <section className="sec-shop-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 mb-3 pt-3">
                            <Carousel cols={4} rows={1} gap={2} breakpoint="767px" loop>
                                <Carousel.Item>
                                    <div
                                        className={`regiondiv ${activeDiv === 29 ? 'active2' : ''}`}
                                        onClick={() => {
                                            setActiveDiv(29);
                                            handleAllPlan();
                                        }}
                                    >
                                        All
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div
                                        className={`regiondiv ${activeDiv === 20 ? 'active2' : ''}`}
                                        onClick={() => {
                                            setActiveDiv(20);
                                            handleSearchPlanByFlag("20", "");
                                        }}
                                    >
                                        Global
                                    </div>
                                </Carousel.Item>
                                {regionResult && regionResult.length > 0 && regionResult.map((region, index) => (
                                    <Carousel.Item key={index}>
                                        <div>
                                            <div
                                                className={`regiondiv ${activeDiv === region.ID ? 'active2' : ''}`}
                                                onClick={() => {
                                                    setActiveDiv(region.ID);
                                                    handleSearchPlanByFlag("19", region.ID);
                                                }}
                                            >
                                                {region.Name}
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                            <div className="tab-content mt-4" id="pills-tabContent">
                                <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="pills-home-tab">
                                    <div className="row row-cols-1 row-cols-lg-4 row-cols-md-2 row-cols-md-1 g-4">
                                        {!searchLoading && plansData && plansData.length > 0 ? plansData.map((plan, index) => {
                                            const imagesvg = `${process.env.REACT_APP_BASEURL}/planimage/${plan.ImageUrl}`;
                                            return (<><div className="col" key={index} style={{ cursor: 'pointer' }}>
                                                <div className="card">
                                                    {plan.FeaturedPlan === 1 && <h1 className="ribbon" style={{ backgroundColor: "#B70404" }}>Featured</h1>}
                                                    {plan.BestSeller === 1 && <h1 className="ribbon">Best Seller</h1>}
                                                    <div className="image-container" onClick={() => navigateToPlanDetailPage(plan.ReferenceURL)}>
                                                        {/* <img src={imagesvg} className="card-img-top img-fluid" alt="..." /> */}
                                                        <Image preview={false} fallback={noImageFound} src={imagesvg} className="card-img-top img-fluid" />
                                                    </div>
                                                    <div className="card-body" >
                                                        <ul className="ul-margin">
                                                            <li className="bahamas" onClick={() => navigateToPlanDetailPage(plan.ReferenceURL)}>{plan.PlanName}</li>
                                                            <li className="nrth-amrica">{plan.PlanDataValue + ' ' + plan.PlanDaysValue}</li>
                                                        </ul>

                                                        <div className="card-text-2">
                                                            <span className="day-5gb">COVERAGE</span>
                                                            {
                                                                plan.AvailableForFlag === 19 ?
                                                                    <span className="dolor india-coverage" data-bs-toggle="modal" data-bs-target={`#countryModel${plan.ID}`}>{plan.CountryISO2 && plan.CountryISO2.length > 0 && plan.CountryISO2.length + " Countries"}</span>
                                                                    :
                                                                    <span className="dolor-coverage-Country-homepage" >{plan.AvailableForIDValue}</span>
                                                            }
                                                        </div>
                                                        <div className='price-text'>
                                                            <span className="dolor">${plan.PlanPrice}</span>
                                                            <Link to={`/plan-details/${plan.ReferenceURL}`} className="shop"><i className="fas fa-shopping-cart"></i></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                                {/* CountryISO3 Model   */}
                                                <div className="modal fade" id={`countryModel${plan.ID}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="exampleModalLabel">Supported Countries</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className='scroll-bar-country-model'>
                                                                    <ul className='ul-country-model'>
                                                                        {plan.CountryISO2 && plan.CountryISO2.length > 0 &&
                                                                            plan.CountryISO2.map((country, index) => {
                                                                                const countryName = countryList.getName(country)
                                                                                return (
                                                                                    <li key={index} className='country-li-flag-name-countymodel' data-bs-toggle="tooltip" data-bs-placement="top" title={countryName}>
                                                                                        {plan.AvailableForFlag === 20 ? "" : <span className='bd-flag'><CountryFlag countryCode={country} svg style={{ width: '39px', height: '41px', borderRadius: "9px", margin: "-6px 0 0 -8px" }} /></span>}
                                                                                    </li>
                                                                                )
                                                                            })}
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            )
                                        }) : searchLoading ?
                                            dummyData.map((item, index) => {
                                                return (
                                                    <div key={index} className="card is-loading" style={{ top: "18px" }} id="card-ab">
                                                        <div className="image"></div>
                                                        <div className="content">
                                                            <h2></h2>
                                                            <p className="p-2"></p>
                                                            <p></p>
                                                            <p className="p-2"></p>
                                                        </div>
                                                    </div>
                                                )
                                            }) : (
                                                <div className="col-lg-12 col-md-12 col-sm-12 pt-4 d-flex align-items-center justify-content-center">
                                                    <div className="noPlanFoundImg">
                                                        <img src={noPlanFoundImg} alt="" />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className='text-center mt-5' style={plansDataLength && plansDataLength >= 5 && plansDataLength % 16 == 0 ? { display: "block" } : { display: "none" }}>
                                    <button className={'become-btn'} onClick={() => handleMoreProduct(16)} ><span>Load More</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* For mobile view*/}
            <section className="mobile-tab">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            {!searchPlanMobileView && <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapse${144}`}
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                            onClick={() => {
                                                // setActiveDiv(20);
                                                handleAllPlan()
                                            }}
                                        >
                                            All
                                        </button>
                                    </h2>
                                    <div
                                        id={`collapse${144}`}
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            {plansData && plansData.length > 0 ? plansData.map((plan, index) => {
                                                const imagesvg = `${process.env.REACT_APP_BASEURL}/planimage/${plan.ImageUrl}`;
                                                return (<>
                                                    <div className="col mb-3" key={index} style={{ cursor: 'pointer' }}>
                                                        <div className="card">
                                                            {plan.FeaturedPlan === 1 && <h1 className="ribbon" style={{ backgroundColor: "#B70404" }}>Featured</h1>}
                                                            {plan.BestSeller === 1 && <h1 className="ribbon">Best Seller</h1>}
                                                            <div className="image-container" onClick={() => navigateToPlanDetailPage(plan.ReferenceURL)}>
                                                                {/* <img src={imagesvg} className="card-img-top img-fluid" alt="..." /> */}
                                                                <Image preview={false} fallback={noImageFound} className="card-img-top img-fluid" src={imagesvg} />
                                                                {plan.AvailableForFlag === 19 &&
                                                                    <p className='text-overlayShop'>{plan.AvailableForIDValue}</p>
                                                                }
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="card-text-1">
                                                                    <div className="text-name">
                                                                        <ul className="ul-margin">
                                                                            <li className="bahamas" onClick={() => navigateToPlanDetailPage(plan.ReferenceURL)}>{plan.PlanName}</li>
                                                                            <li className="nrth-amrica"> {plan.AvailableForIDValue}</li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="text-icon"> <span className="shop"> <Link to={`/plan-details/${plan.ReferenceURL}`}><i className="fas fa-shopping-cart"></i></Link></span></div>
                                                                </div>
                                                                <div className="card-text-2">
                                                                    <span className="day-5gb">COVERAGE</span>
                                                                    {
                                                                        plan.AvailableForFlag === 19 ?
                                                                            <span className="dolor india-coverage" data-bs-toggle="modal" data-bs-target={`#countryModel${plan.ID}`}>{plan.CountryISO2 && plan.CountryISO2.length > 0 && plan.CountryISO2.length + " Countries"}</span>
                                                                            :
                                                                            <span className="dolor" >{plan.AvailableForIDValue}</span>
                                                                    }
                                                                </div>
                                                                <div className="card-text-2">
                                                                    <span className="day-5gb">{plan.PlanDataValue + ' ' + plan.PlanDaysValue}</span>
                                                                    <span className="dolor">${plan.PlanPrice}</span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* CountryISO3 Model   */}
                                                    <div className="modal fade" id={`countryModel${plan.ID}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="exampleModalLabel">Supported Countries</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <div className='scroll-bar-country-model'>
                                                                        <ul className='ul-country-model'>
                                                                            {plan.CountryISO2 && plan.CountryISO2.length > 0 &&
                                                                                plan.CountryISO2.map((country, index) => {
                                                                                    const countryName = countryList.getName(country)
                                                                                    return (
                                                                                        <li key={index} className='country-li-flag-name-countymodel' data-bs-toggle="tooltip" data-bs-placement="top" title={countryName}>
                                                                                            {plan.AvailableForFlag === 20 ? "" : <span className='bd-flag'><CountryFlag countryCode={country} svg style={{ width: '39px', height: '41px', borderRadius: "9px", margin: "-6px 0 0 -8px" }} /></span>}
                                                                                        </li>
                                                                                    )
                                                                                })}
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </>)
                                            }) :
                                                <div className='col-lg-12 col-md-12 col-sm-12 pt-4 d-flex align-items-center justify-content-center'>
                                                    <div className='noPlanFoundImg'>
                                                        <img src={noPlanFoundImg} alt="" />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                < div className="accordion-item" >
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapse${20}`}
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                            onClick={() => {
                                                setActiveDiv(20);
                                                handleSearchPlanByFlag("20", "");
                                            }}
                                        >
                                            Global
                                        </button>
                                    </h2>
                                    <div
                                        id={`collapse${20}`}
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body">
                                            {plansData && plansData.length > 0 ? plansData.map((plan, index) => {
                                                const imagesvg = `${process.env.REACT_APP_BASEURL}/planimage/${plan.ImageUrl}`;
                                                return (<>
                                                    <div className="col mb-3" key={index} style={{ cursor: 'pointer' }}>
                                                        <div className="card">
                                                            {plan.FeaturedPlan === 1 && <h1 className="ribbon" style={{ backgroundColor: "#B70404" }}>Featured</h1>}
                                                            {plan.BestSeller === 1 && <h1 className="ribbon">Best Seller</h1>}
                                                            <div className="image-container" onClick={() => navigateToPlanDetailPage(plan.ReferenceURL)}>
                                                                {/* <img src={imagesvg} className="card-img-top img-fluid" alt="..." /> */}
                                                                <Image preview={false} fallback={noImageFound} className="card-img-top img-fluid" src={imagesvg} />
                                                                {plan.AvailableForFlag === 19 &&
                                                                    <p className='text-overlayShop'>{plan.AvailableForIDValue}</p>
                                                                }
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="card-text-1">
                                                                    <div className="text-name">
                                                                        <ul className="ul-margin">
                                                                            <li className="bahamas" onClick={() => navigateToPlanDetailPage(plan.ReferenceURL)}>{plan.PlanName}</li>
                                                                            <li className="nrth-amrica"> {plan.AvailableForIDValue}</li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="text-icon"> <span className="shop"> <Link to={`/plan-details/${plan.ReferenceURL}`}><i className="fas fa-shopping-cart"></i></Link></span></div>
                                                                </div>
                                                                <div className="card-text-2">
                                                                    <span className="day-5gb">COVERAGE</span>
                                                                    {
                                                                        plan.AvailableForFlag === 19 ?
                                                                            <span className="dolor india-coverage" data-bs-toggle="modal" data-bs-target={`#countryModel${plan.ID}`}>{plan.CountryISO2 && plan.CountryISO2.length > 0 && plan.CountryISO2.length + " Countries"}</span>
                                                                            :
                                                                            <span className="dolor" >{plan.AvailableForIDValue}</span>
                                                                    }
                                                                </div>
                                                                <div className="card-text-2">
                                                                    <span className="day-5gb">{plan.PlanDataValue + ' ' + plan.PlanDaysValue}</span>
                                                                    <span className="dolor">${plan.PlanPrice}</span>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* CountryISO3 Model   */}
                                                    <div className="modal fade" id={`countryModel${plan.ID}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5 className="modal-title" id="exampleModalLabel">Supported Countries</h5>
                                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <div className='scroll-bar-country-model'>
                                                                        <ul className='ul-country-model'>
                                                                            {plan.CountryISO2 && plan.CountryISO2.length > 0 &&
                                                                                plan.CountryISO2.map((country, index) => {
                                                                                    const countryName = countryList.getName(country)
                                                                                    return (
                                                                                        <li key={index} className='country-li-flag-name-countymodel' data-bs-toggle="tooltip" data-bs-placement="top" title={countryName}>
                                                                                            {plan.AvailableForFlag === 20 ? "" : <span className='bd-flag'><CountryFlag countryCode={country} svg style={{ width: '39px', height: '41px', borderRadius: "9px", margin: "-6px 0 0 -8px" }} /></span>}
                                                                                        </li>
                                                                                    )
                                                                                })}
                                                                        </ul>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </>)
                                            }) :
                                                <div className='col-lg-12 col-md-12 col-sm-12 pt-4 d-flex align-items-center justify-content-center'>
                                                    <div className='noPlanFoundImg'>
                                                        <img src={noPlanFoundImg} alt="" />
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {regionResult && regionResult.length > 0 && regionResult.map((region, index) => {
                                    return (<>
                                        < div key={index} className="accordion-item" >
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#collapse${region.ID}`}
                                                    aria-expanded="false"
                                                    aria-controls="collapseTwo"
                                                    onClick={() => {
                                                        setActiveDiv(region.ID);
                                                        handleSearchPlanByFlag("19", region.ID);
                                                    }}
                                                >
                                                    {region.Name}
                                                </button>
                                            </h2>
                                            <div
                                                id={`collapse${region.ID}`}
                                                className="accordion-collapse collapse"
                                                aria-labelledby="headingTwo"
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="accordion-body">
                                                    {plansData && plansData.length > 0 ? plansData.map((plan, index) => {
                                                        const imagesvg = `${process.env.REACT_APP_BASEURL}/planimage/${plan.ImageUrl}`;
                                                        return (<><div className="col mb-3" key={index}>
                                                            <div className="card">
                                                                {plan.FeaturedPlan === 1 && <h1 className="ribbon" style={{ backgroundColor: "#B70404" }}>Featured</h1>}
                                                                {plan.BestSeller === 1 && <h1 className="ribbon">Best Seller</h1>}
                                                                <div className="image-container" onClick={() => navigateToPlanDetailPage(plan.ReferenceURL)}>
                                                                    {/* <img src={imagesvg} className="card-img-top img-fluid" alt="..." /> */}
                                                                    <Image preview={false} fallback={noImageFound} className="card-img-top img-fluid" src={imagesvg} />
                                                                    {plan.AvailableForFlag === 19 &&
                                                                        <p className='text-overlayShop'>{plan.AvailableForIDValue}</p>
                                                                    }
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="card-text-1">
                                                                        <div className="text-name">
                                                                            <ul className="ul-margin">
                                                                                <li className="bahamas" onClick={() => navigateToPlanDetailPage(plan.ReferenceURL)}>{plan.PlanName}</li>
                                                                                <li className="nrth-amrica"> {plan.AvailableForIDValue}</li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="text-icon"> <span className="shop"> <Link to={`/plan-details/${plan.ReferenceURL}`}><i className="fas fa-shopping-cart"></i></Link></span></div>
                                                                    </div>

                                                                    <div className="card-text-2">
                                                                        <span className="day-5gb">COVERAGE</span>
                                                                        {
                                                                            plan.AvailableForFlag === 19 ?
                                                                                <span className="dolor-coverage-Country india-coverage" data-bs-toggle="modal" data-bs-target={`#countryModel${region.ID}${plan.ID}`}>{plan.CountryISO2 && plan.CountryISO2.length > 0 && plan.CountryISO2.length + " Countries"}</span>
                                                                                :
                                                                                <span className="dolor-coverage-Country" >{plan.AvailableForIDValue}</span>
                                                                        }
                                                                    </div>
                                                                    <div className="card-text-2">
                                                                        <span className="day-5gb">{plan.PlanDataValue + ' ' + plan.PlanDaysValue}</span>
                                                                        <span className="dolor">${plan.PlanPrice}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                            {/* CountryISO3 Model   */}
                                                            <div className="modal fade" id={`countryModel${region.ID}${plan.ID}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                <div className="modal-dialog">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title" id="exampleModalLabel">Supported Countries</h5>
                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                        </div>
                                                                        <div className="modal-body">
                                                                            <div className='scroll-bar-country-model'>
                                                                                <ul className='ul-country-model'>
                                                                                    {plan.CountryISO2 && plan.CountryISO2.length > 0 &&
                                                                                        plan.CountryISO2.map((country, index) => {
                                                                                            const countryName = countryList.getName(country)
                                                                                            return (
                                                                                                <li key={index} className='country-li-flag-name-countymodel' data-bs-toggle="tooltip" data-bs-placement="top" title={countryName}>
                                                                                                    {plan.AvailableForFlag === 20 ? "" : <span className='bd-flag'><CountryFlag countryCode={country} svg style={{ width: '39px', height: '41px', borderRadius: "9px", margin: "-6px 0 0 -8px" }} /></span>}
                                                                                                </li>
                                                                                            )
                                                                                        })}
                                                                                </ul>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>)
                                                    }) :
                                                        <div className='col-lg-12 col-md-12 col-sm-12 pt-4 d-flex align-items-center justify-content-center'>
                                                            <div className='noPlanFoundImg'>
                                                                <img src={noPlanFoundImg} alt="" />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    )
                                })}
                            </div>}

                            {plansData && plansData.length > 0 ? plansData.map((plan, index) => {
                                const imagesvg = `${process.env.REACT_APP_BASEURL}/planimage/${plan.ImageUrl}`;
                                return (

                                    <>
                                        {
                                            searchPlanMobileView && <>
                                                <div className="col mb-3" key={index} style={{ cursor: 'pointer' }}>
                                                    <div className="card">
                                                        {plan.FeaturedPlan === 1 && <h1 className="ribbon" style={{ backgroundColor: "#B70404" }}>Featured</h1>}
                                                        {plan.BestSeller === 1 && <h1 className="ribbon">Best Seller</h1>}
                                                        <div className="image-container" onClick={() => navigateToPlanDetailPage(plan.ReferenceURL)}>
                                                            {/* <img src={imagesvg} className="card-img-top img-fluid" alt="..." /> */}
                                                            <Image preview={false} fallback={noImageFound} className="card-img-top img-fluid" src={imagesvg} />
                                                            {plan.AvailableForFlag === 19 &&
                                                                <p className='text-overlayShop'>{plan.AvailableForIDValue}</p>
                                                            }
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="card-text-1">
                                                                <div className="text-name">
                                                                    <ul className="ul-margin">
                                                                        <li className="bahamas" onClick={() => navigateToPlanDetailPage(plan.ReferenceURL)}>{plan.PlanName}</li>
                                                                        <li className="nrth-amrica"> {plan.AvailableForIDValue}</li>
                                                                    </ul>
                                                                </div>
                                                                <div className="text-icon"> <span className="shop"> <Link to={`/plan-details/${plan.ReferenceURL}`}><i className="fas fa-shopping-cart"></i></Link></span></div>
                                                            </div>
                                                            <div className="card-text-2">
                                                                <span className="day-5gb">COVERAGE</span>
                                                                {
                                                                    plan.AvailableForFlag === 19 ?
                                                                        <span className="dolor india-coverage" data-bs-toggle="modal" data-bs-target={`#countryModel${plan.ID}`}>{plan.CountryISO2 && plan.CountryISO2.length > 0 && plan.CountryISO2.length + " Countries"}</span>
                                                                        :
                                                                        <span className="dolor" >{plan.AvailableForIDValue}</span>
                                                                }
                                                            </div>
                                                            <div className="card-text-2">
                                                                <span className="day-5gb">{plan.PlanDataValue + ' ' + plan.PlanDaysValue}</span>
                                                                <span className="dolor">${plan.PlanPrice}</span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                {/* CountryISO3 Model   */}
                                                <div className="modal fade" id={`countryModel${plan.ID}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                    <div className="modal-dialog">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="exampleModalLabel">Supported Countries</h5>
                                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className='scroll-bar-country-model'>
                                                                    <ul className='ul-country-model'>
                                                                        {plan.CountryISO2 && plan.CountryISO2.length > 0 &&
                                                                            plan.CountryISO2.map((country, index) => {
                                                                                const countryName = countryList.getName(country)
                                                                                return (
                                                                                    <li key={index} className='country-li-flag-name-countymodel' data-bs-toggle="tooltip" data-bs-placement="top" title={countryName}>
                                                                                        {plan.AvailableForFlag === 20 ? "" : <span className='bd-flag'><CountryFlag countryCode={country} svg style={{ width: '39px', height: '41px', borderRadius: "9px", margin: "-6px 0 0 -8px" }} /></span>}
                                                                                    </li>
                                                                                )
                                                                            })}
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                        }

                                    </>)
                            }) :
                                <div className='col-lg-12 col-md-12 col-sm-12 pt-4 d-flex align-items-center justify-content-center'>
                                    <div className='noPlanFoundImg'>
                                        <img src={noPlanFoundImg} alt="" />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Shop;