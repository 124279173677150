import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList } from '../../helpers/fackBackend_Helper';

function* createOrderRequestResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/createCustomerOrder', data);
        if (response.response.Status) {
            yield put(
                actions.createOrderRequestSuccess(
                    response.response.OrderId, response.response.Message, response.response.OrderNumber
                ));
        } else {
            yield put(actions.createOrderRequestFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.createOrderRequestFailed(error));
    }
}

function* sendOrderDataRequestResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/addPackageToSIM', data);
        if (response.response.Status) {
            yield put(
                actions.sendOrderDataRequestSuccess(
                    response.response
                ));
        } else {
            yield put(actions.sendOrderDataRequestFailed(response.response));
        }
    } catch (error) {
        yield put(actions.sendOrderDataRequestFailed(error));
    }
}

function* getPaymentGatewayResponse() {
    try {
        const response = yield call(getList, '/getPaymentGatewayForCheckout');
        if (response.response.Status) {
            yield put(actions.getPaymentGatewaySuccess(response.response.PaymentGateway));
        } else {
            yield put(actions.getPaymentGatewayFailed(response.response));
        }
    } catch (error) {
        yield put(actions.getPaymentGatewayFailed(error));
    }
}

function* applyCouponResponse({ payload: { data} }) {
    try {
        const response = yield call(addData, '/applyCoupon' ,data);
        if (response.response[0].Status ) {
            yield put(actions.applyCouponSuccess(response.response[0].Message,response.response));
        } else {
            yield put(actions.applyCouponFailed( response.response[0].Message ));    
        }
        
    } catch (error) {
        yield put(actions.applyCouponFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.CREATE_ORDER_REQUEST, createOrderRequestResponse)]);
    yield all([takeEvery(actions.SEND_ORDER_DATA_REQUEST, sendOrderDataRequestResponse)]);
    yield all([takeEvery(actions.GET_PAYMENT_GATEWAY, getPaymentGatewayResponse)]);
    yield all([takeEvery(actions.COUPON_APPLY, applyCouponResponse)]);
}