import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import authActions from '../../redux/auth/actions';
import { Form, Input, Button, message } from "antd";
import logo from '../../assets/image/virtuzo-logo.png';
import Loading from '../Loader/Loading';
function Signup() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { signUpRequest, resetAuthError } = authActions;
    const successMessage = useSelector((state) => state.authReducer.message);
    const errorMessage = useSelector((state) => state.authReducer.error);
    const loading = useSelector((state) => state.authReducer.loading);

    const [form] = Form.useForm();

    const handleLoginActive = () => {
        history.push('/login');
    }

    const handleSubmitSignUpData = (values) => {
        delete values.confirmPassword
        dispatch(signUpRequest(values));
    }

    const error = (text) => {
        const hide = message.error(
            //   <MessageContent>{text}</MessageContent>,
            <p style={{
                'display': 'inline-block',
                'fontSize': '15px'
            }}>{text}</p>,
            0
        );
        setTimeout(hide, 2000);
        dispatch(resetAuthError());
    };

    const success = (text) => {
        const hide = message.success(
            //   <MessageContent>{text}</MessageContent>,
            <p style={{
                'display': 'inline-block',
                'fontSize': '15px'
            }}>{text}</p>,
            0
        );
        setTimeout(hide, 2000);
        form.resetFields();
        dispatch(resetAuthError());
    };

    return (
        <section className="login-sec-1 login-bg">
            {/* alert message show */}
            {successMessage !== null && successMessage !== undefined && success(successMessage)}
            {errorMessage !== null && errorMessage !== undefined && error(errorMessage)}
            {loading && <Loading />}
            <div className="container">
                <div className="row d-flex align-item-center justify-content-center py-2 px-3">
                    <div className="col-lg-4 form-tab-1 py-3 ">
                        <Link to="/"> <img src={logo} width="250px" className="py-3 mb-4" id='logo-fold-size'></img></Link>
                        {/* <h2 className="py-3"><a href="index.html">logo</a></h2> */}
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            {<li className="login-tab" role="presentation">
                                <button className="nav-link" id="pills-home-tab1" data-bs-toggle="pill" data-bs-target="#pills-home1"
                                    type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={handleLoginActive}>Login</button>
                            </li>}
                            <li className="login-tab" role="presentation">
                                <button className="nav-link active" id="pills-profile-tab1" data-bs-toggle="pill" data-bs-target="#pills-profile1"
                                    type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Sign Up</button>
                            </li>

                        </ul>
                        <div className="tab-content  d-flex align-item-center justify-content-center" id="pills-tabContent1">
                            {/* signUpForm Here */}
                            <div className="tab-pane fade active show" id="pills-profile1" role="tabpanel" aria-labelledby="pills-profile-tab1">
                                <div className="login-title">
                                    <h5>Join For Free</h5>
                                </div>

                                <Form form={form} className="row-col" size="large" onFinish={handleSubmitSignUpData}>
                                    <Form.Item className="username" name="firstName" rules={[{ required: true, message: "Please input your firstname!", }]}>
                                        <Input placeholder="First Name" name="firstName" />
                                    </Form.Item>
                                    <Form.Item className="username" name="lastName" rules={[{ required: true, message: "Please input your lastname!", }]}>
                                        <Input placeholder="Last Name" name="lastName" />
                                    </Form.Item>
                                    <Form.Item className="username" name="email" rules={[{ required: true, message: "Please input your email!", }, { type: "email", message: "Please input a valid email!" }]}>
                                        <Input placeholder="Email Address" name="email" />
                                    </Form.Item>
                                    <Form.Item className="username" name="password" rules={[{ required: true, message: 'Please input your password!', },]} hasFeedback>
                                        <Input.Password placeholder="Create password" name="password" />
                                    </Form.Item>

                                    <Form.Item name="confirmPassword" dependencies={['password']} hasFeedback rules={[{ required: true, message: 'Please confirm your password!', },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The Confirm passwords does not match with entered password!'));
                                        },
                                    }),]}>
                                        <Input.Password name="confirmPassword" placeholder="Confirm Password" />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" className='login-btn' style={{ width: "100%" }} > SIGN UP </Button>
                                    </Form.Item>
                                </Form>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Signup;