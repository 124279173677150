const actions = {
    GET_TESTIMONIALS_DATA: 'GET_TESTIMONIALS_DATA',
    GET_TESTIMONIALS_DATA_SUCCESS: 'GET_TESTIMONIALS_DATA_SUCCESS',
    GET_TESTIMONIALS_DATA_FAILED: 'GET_TESTIMONIALS_DATA_FAILED',
    getTestimonialsData: () => ({
        type: actions.GET_TESTIMONIALS_DATA,
    }),
    getTestimonialsDataSuccess: (testimonialsResult) => ({
        type: actions.GET_TESTIMONIALS_DATA_SUCCESS,
        testimonialsResult
    }),
    getTestimonialsDataFailed: (testimonialsError) => ({
        type: actions.GET_TESTIMONIALS_DATA_FAILED,
        testimonialsError
    }),
}
export default actions;