import actions from "./actions";

const initState = {
    testimonialsResult: [],
    loading: false,
    testimonialsError: null,
    testimonialsMessage: null,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_TESTIMONIALS_DATA:
            return {
                ...state,
                loading: true,
                testimonialsError: null,
                testimonialsMessage: null, 
            };
        case actions.GET_TESTIMONIALS_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                testimonialsError: null,
                testimonialsResult: action.testimonialsResult,
                testimonialsMessage: null,
            };
        case actions.GET_TESTIMONIALS_DATA_FAILED:
            return {
                ...state,
                loading: false,
                testimonialsError: action.testimonialsError,
                testimonialsMessage: null,
            };
        default:
            return state;
    }
}