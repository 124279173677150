import React from 'react'
import NavBar from './NavBar'
import Footer from './Footer'

function Main({children}) {
  return (
    <>
    <NavBar></NavBar>
    {children}
    <Footer></Footer>
    </>
  )
}

export default Main