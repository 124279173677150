const actions = {
    GET_SEARCH_PLAN: "GET_SEARCH_PLAN",
    GET_SEARCH_PLAN_SUCCESS: "GET_SEARCH_PLAN_SUCCESS",
    GET_SEARCH_PLAN_FAILED: "GET_SEARCH_PLAN_FAILED",
    GET_PLAN_DATA: "GET_PLAN_DATA",
    GET_PLAN_DATA_SUCCESS: "GET_PLAN_DATA_SUCCESS",
    GET_PLAN_DATA_FAILED: "GET_PLAN_DATA_FAILED",
    GET_PLAN_DATA_BEST_SELLER: "GET_PLAN_DATA_BEST_SELLER",
    GET_PLAN_DATA_BEST_SELLER_SUCCESS: "GET_PLAN_DATA_BEST_SELLER_SUCCESS",
    GET_PLAN_DATA_BEST_SELLER_FAILED: "GET_PLAN_DATA_BEST_SELLER_FAILED",
    CHECK_COMPATIBLE_STATUS: "CHECK_COMPATIBLE_STATUS",
    CHECK_COMPATIBLE_STATUS_SUCCESS: "CHECK_COMPATIBLE_STATUS_SUCCESS",
    CHECK_COMPATIBLE_STATUS_FAILED: "CHECK_COMPATIBLE_STATUS_FAILED",
    RESET_HOME_PAGE: "RESET_HOME_PAGE",
    GET_PLAN_BY_REFERENCE_URL: "GET_PLAN_BY_REFERENCE_URL",
    GET_PLAN_BY_REFERENCE_URL_SUCCESSFULL: "GET_PLAN_BY_REFERENCE_URL_SUCCESSFULL",
    GET_PLAN_BY_REFERENCE_URL_FAILED: "GET_PLAN_BY_REFERENCE_URL_FAILED",
    VALIDATE_TOKEN: "VALIDATE_TOKEN",
    VALIDATE_TOKEN_SUCCESS: "VALIDATE_TOKEN_SUCCESS",
    VALIDATE_TOKEN_FAILED: "VALIDATE_TOKEN_FAILED",
    GET_BANNER_DATA: "GET_BANNER_DATA",
    GET_BANNER_DATA_SUCCESS: "GET_BANNER_DATA_SUCCESS",
    GET_BANNER_DATA_FAILED: "GET_BANNER_DATA_FAILED",

    getSearchPlan: (data) => ({
        type: actions.GET_SEARCH_PLAN,
        payload: { data },
    }),
    getSearchPlanSuccess: (searchCountryResult, searchRegionResult) => ({
        type: actions.GET_SEARCH_PLAN_SUCCESS,
        searchCountryResult, searchRegionResult
    }),
    getSearchPlanFailed: (error) => ({
        type: actions.GET_SEARCH_PLAN_FAILED,
        error
    }),

    validateToken: () => ({
        type: actions.VALIDATE_TOKEN,
    }),
    validateTokenSuccess: (tokenMessage) => ({
        type: actions.VALIDATE_TOKEN_SUCCESS,
        tokenMessage
    }),
    validateTokenFailed: (tokenError) => ({
        type: actions.VALIDATE_TOKEN_FAILED,
        tokenError
    }),
    getPlanData: (filterData) => ({
        type: actions.GET_PLAN_DATA,
        payload: { filterData },
    }),
    getPlanDataSuccess: (planDataResult) => ({
        type: actions.GET_PLAN_DATA_SUCCESS,
        planDataResult
    }),
    getPlanDataFailed: (error) => ({
        type: actions.GET_PLAN_DATA_FAILED,
        error
    }),
    getPlanDataBestSeller: (filterData) => ({
        type: actions.GET_PLAN_DATA_BEST_SELLER,
        payload: { filterData },
    }),
    getPlanDataBestSellerSuccess: (planDataResult) => ({
        type: actions.GET_PLAN_DATA_BEST_SELLER_SUCCESS,
        planDataResult
    }),
    getPlanDataBestSellerFailed: (error) => ({
        type: actions.GET_PLAN_DATA_BEST_SELLER_FAILED,
        error
    }),
    checkCompatibleStatus: (data) => ({
        type: actions.CHECK_COMPATIBLE_STATUS,
        payload: { data },
    }),
    checkCompatibleStatusSuccess: (compatibleMesssage, imeiNumber, response) => ({
        type: actions.CHECK_COMPATIBLE_STATUS_SUCCESS,
        compatibleMesssage,
        imeiNumber,
        response
    }),
    checkCompatibleStatusFailed: (compatibleError, imeiNumber) => ({
        type: actions.CHECK_COMPATIBLE_STATUS_FAILED,
        compatibleError,
        imeiNumber
    }),
    resetHomePage: () => ({
        type: actions.RESET_HOME_PAGE,
    }),
    getPlanByReferenceURL: (referenceURL) => ({
        type: actions.GET_PLAN_BY_REFERENCE_URL,
        payload: { referenceURL },
    }),
    getPlanByReferenceURLSuccess: (planDetailsResult) => ({
        type: actions.GET_PLAN_BY_REFERENCE_URL_SUCCESSFULL,
        planDetailsResult
    }),
    getPlanByReferenceURLFailed: (error) => ({
        type: actions.GET_PLAN_BY_REFERENCE_URL_FAILED,
        error
    }),
    getBanner: () => ({
        type: actions.GET_BANNER_DATA,
    }),
    getBannerSuccess: (bannerResult) => ({
        type: actions.GET_BANNER_DATA_SUCCESS,
        bannerResult
    }),
    getBannerFailed: (error) => ({
        type: actions.GET_BANNER_DATA_FAILED,
        error
    }),
};
export default actions;