import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import image1 from '../../assets/image/Icon material-devices-other.png';
import largest from '../../assets/image/phone-tablet-and-laptop.png';
import Seamless from '../../assets/image/solution.png';
import compitative from '../../assets/image/bid.png';
import flexible from '../../assets/image/flexible.png';
import revenue from '../../assets/image/revenue.png';
import image5 from '../../assets/image/about-2-1.png';
import image6 from '../../assets/image/system-penal.png';
import global from '../../assets/image/global.png';
import support from '../../assets/image/support.png';
import laptopimg from '../../assets/image/laptop-frame-img.png';
import management from '../../assets/image/team.png';
import privacy from '../../assets/image/private-network.png';
import image7 from '../../assets/image/moble-penal.png';
import { useSelector, useDispatch } from 'react-redux';
import partnerActions from '../../redux/partner/actions';
import Loading from '../Loader/Loading';
import { Button, Form, Input, Alert } from "antd";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

function Partner() {
    const dispatch = useDispatch();
    const { addDealerData, resetDealer } = partnerActions;
    const buttonLoading = useSelector((state) => state.partnerdataReducer.buttonLoading);
    const [message, setmessage] = useState(useSelector((state) => state.partnerdataReducer.dealerMessage));
    const successMessage = useSelector((state) => state.partnerdataReducer.dealerMessage);
    const errorMessage = useSelector((state) => state.partnerdataReducer.dealerError);
    const closeButtonRef = useRef(null);
    const [form] = Form.useForm();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const handleSubmit = (value) => {
        dispatch(addDealerData(value));
        setTimeout(() => {
            handleClose();
        }, 10000);
    };

    const handleClose = () => {
        form.resetFields();
        dispatch(resetDealer());
    }

    const error = (text) => {
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: `${text}`,
            // text: `${text}`,
            showConfirmButton: true,
            customClass: {
                popup: 'custom-popup-className',
                title: 'custom-title-className',
                confirmButton: 'custom-button-className',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                // Perform your desired action here when the user clicks "OK"
                handleClose();
                // Add your code here...
            }
        });
    };

    const success = (text) => {
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: `${text} check your email`,
            // text: `${text} check your email`,
            showConfirmButton: true,
            customClass: {
                popup: 'custom-popup-className',
                title: 'custom-title-className',
                confirmButton: 'custom-button-className',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                if (successMessage !== null) {
                    form.resetFields();
                }
                // Perform your desired action here when the user clicks "OK"
                handleClose();
                closeButtonRef.current.click();
                // Add your code here...
            }
        });
    };

    const handleNumber = (e) => {
        const keyCode = e.keyCode;
        if (((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) && e.keyCode != 8) {
            e.preventDefault();
        }
    }
    return (
        <>
            {successMessage !== null && successMessage !== undefined && success(successMessage)}
            {errorMessage !== null && errorMessage !== undefined && error(errorMessage)}
            {/* section 1 */}
            <section className="sec-patner">
                <div className="container">
                    <div className="row py-4">
                        <div className="col-lg-6 col-md-6 col-sm-12 ">
                            <div className="col keepgo-h">
                                <h3 className="keepgo-sim">Join the eSIM Revolution Today</h3>
                                <p className="create-sim-para">Are you looking to expand your business offerings and provide cutting-edge solutions to your customers? Partnering with us to sell eSIM</p>
                                <Link className="become-btn " data-bs-toggle="modal" data-bs-target="#becomePartnerModal" data-bs-whatever="@mdo" to="#"><span>Become a Partner</span></Link>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 img-material">
                            <img id="laptopanimate" src={laptopimg} className="img-fluid" alt="..." />
                        </div>
                    </div>
                </div>
            </section>
            {/* section 1 */}

            {/* section 2 */}
            <section className="sec-patner-2 py-5">
                {/* <p>UNLOCK A GLOBAL MARKETPLACE SHARE YOUR CONNECTIVITY</p> */}
                <h3 >Why Partner with Us?</h3>
                <div className="container">
                    <div className="row pt-4">
                        <div className="col-lg-4 col-md-6 col-sm-12  md-b align-item-center justify-content-center">
                            <div className="card-icon">
                                <div className="img-icon">
                                    <img id="img-hovers" src={largest} className="img-fluid" alt="..." />
                                </div>
                                <div className="icon-text">
                                    <p className="icons-para-text">Largest eSIM Portfolios</p>
                                    <p>As a partner, you'll gain access to our wide range of eSIM services, allowing you to offer your customers the flexibility and convenience of eSIM technology for their devices. Whether they need eSIMs for travel, business purposes, or personal use, we've got them covered.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12  md-b align-item-center justify-content-center">
                            <div className="card-icon">
                                <div className="img-icon">
                                    <img id="img-hovers" src={Seamless} className="img-fluid" alt="..." />
                                </div>
                                <div className="icon-text">
                                    <p className="icons-para-text">Seamless Integration</p>
                                    <p>Our partnership program is designed to ensure a smooth integration process. We provide you with the necessary tools, APIs, and support to integrate our eSIM services seamlessly into your existing platform or services.</p>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-4 col-md-6 col-sm-12  md-b align-item-center justify-content-center">
                            <div className="card-icon">
                                <div className="img-icon">
                                    <img id="img-hovers" src={compitative} className="img-fluid" alt="..." />
                                </div>
                                <div className="icon-text">
                                    <p className="icons-para-text">Competitive Advantage</p>
                                    <p>By offering eSIM services to your customers, you can differentiate your business from competitors and stay ahead in the rapidly evolving mobile connectivity landscape. eSIM technology is the future of mobile connectivity, and partnering with us will position your business as an innovative leader.</p>
                                </div>
                            </div>
                        </div>



                        <div className="col-lg-4 col-md-6 col-sm-12 md-b align-item-center justify-content-center">
                            <div className="card-icon">
                                <div className="img-icon">
                                    <img id="img-hovers" src={revenue} className="img-fluid" alt="..." />
                                </div>
                                <div className="icon-text">
                                    <p className="icons-para-text">Revenue Generation</p>
                                    <p>As a partner, you'll have the opportunity to generate additional revenue streams by earning commissions or revenue share based on the volume of eSIM services sold through your platform or referrals.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-12  md-b align-item-center justify-content-center">
                            <div className="card-icon">
                                <div className="img-icon">
                                    <img id="img-hovers" src={support} className="img-fluid" alt="..." />
                                </div>
                                <div className="icon-text">
                                    <p className="icons-para-text">Dedicated Support</p>
                                    <p>We believe in building strong partnerships, and our dedicated support team will be there to assist you every step of the way. From onboarding to ongoing support, we are committed to ensuring your success as a partner.</p>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-4 col-md-6 col-sm-12  md-b  align-item-center justify-content-center">
                            <div className="card-icon">
                                <div className="img-icon">
                                    <img id="img-hovers" src={flexible} className="img-fluid" alt="..." />
                                </div>
                                <div className="icon-text">
                                    <p className="icons-para-text">Flexibility and Scalability</p>
                                    <p>Our partner program is designed to accommodate businesses of all sizes. Whether you're a small reseller or a large telecommunications company, our program is flexible and scalable to fit your unique business requirements..</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {/* section 2 */}

            {/* section 3 */}
            <section className="sec-partner-3 py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <img id="animate-1" src={image5} className="img-fluid" alt="..." />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            {/* <p className="about-emap-para">About ESIM Mapping</p> */}
                            <h3 className="jion-our-title">Take your business to new heights by becoming a valued partner! </h3>
                            <p className="zim-cover-para"><b>esim source</b> covers over 150 destinations worldwide, and provides eSIM dashboard and consumption reports. If you are traveltech.</p>
                        </div>
                    </div>
                </div>
            </section>
            {/* section 3 */}

            {/* section 4 */}
            <section className="sec-partner-4">
                <h3 >Our eSIM Provisioning Platform offers</h3>
                <div className="container">
                    <div className="row py-4 d-flex align-item-center justify-content-center">

                        <div className="col-lg-5 col-md-6 col-sm-12">
                            <div className="card mb-3  card-1-new " >
                                <div className="row g-0 padding-size">
                                    <div className="col-md-4 img-contol-panel mt-left">
                                        <img id="white-img" src={image6} className="img-fluid rounded-start" alt="..." />
                                    </div>
                                    <div className=" col-lg-8 col-md-12 ">
                                        <div className="card-body">
                                            <h5 className="control-panel-h5">Seamless eSIM integration </h5>
                                            <p className='get-para'>Easily integrate our platform into your existing systems and services, using our easy to connect API</p>

                                        </div>
                                    </div>
                                </div>

                                <div className="go-corner">
                                    <div className="go-arrow">
                                        →
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-5 col-md-6 col-sm-12  ">
                            <div className="card mb-3 card-1-new  boxes ">
                                <div className="row g-0 padding-size">
                                    <div className="col-md-4 img-contol-panel mt-left">
                                        <img id="white-img" src={management} className="img-fluid rounded-start" alt="..." />
                                    </div>
                                    <div className="col-lg-8 col-md-12">
                                        <div className="card-body">
                                            <h5 className="control-panel-h5">Flexible Management</h5>
                                            <p className='get-para'>Our platform enables easy management of eSIM profiles, empowering you to remotely provision, update, and manage eSIMs for your customers, </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="go-corner" >
                                    <div className="go-arrow">
                                        →
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12">
                            <div className="card mb-3 card-1-new boxes " >
                                <div className="row g-0 ">
                                    <div className="col-md-4 img-contol-panel mt-left">
                                        <img id="white-img" src={privacy} className="img-fluid rounded-start" alt="..." />
                                    </div>
                                    <div className="col-lg-8 col-md-12">
                                        <div className="card-body">
                                            <h5 className="control-panel-h5-h">Robust security and privacy</h5>
                                            <p className="get-para">Our platform ensures the highest level of security for provisioning and managing eSIM profiles, safeguarding sensitive customer data and protecting against unauthorized access.</p>

                                        </div>
                                    </div>
                                </div>

                                <div className="go-corner" >
                                    <div className="go-arrow">
                                        →
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12">
                            <div className="card  card-1-new  mb-3 boxes " >
                                <div className="row g-0 padding-size">
                                    <div className="col-md-4 img-contol-panel mt-left">
                                        <img id="white-img" src={global} className="img-fluid rounded-start" alt="..." />
                                    </div>
                                    <div className="col-lg-8 col-md-12">
                                        <div className="card-body">
                                            <h5 className="control-panel-h5">Global Coverage</h5>
                                            <p className='get-para'>With our eSIM provisioning platform, you can offer your customers global connectivity, allowing them to access mobile networks in various countries without the need for physical SIM cards.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="go-corner">
                                    <div className="go-arrow">
                                        →
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {/* section 4 */}


            {/* modal */}
            <div className="modal fade" id="becomePartnerModal" data-bs-backdrop='static'>
                <div className="modal-dialog modal-lg modal-sm">
                    <div className="modal-content animate">
                        <div className="modal-header">
                            <button type="button" onClick={handleClose} className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={closeButtonRef} ></button>
                        </div>
                        <div className="modal-body">
                            <div className="mb-3 contact-sale">
                                <h5>Contact Sale</h5>
                                <p>Fill out the form below, and we will get in touch within 24 hours</p>
                            </div>
                            <Form form={form} className="row-col" size="large" onFinish={handleSubmit}>
                                <Form.Item className="username" name="contactPerson" rules={[{ required: true, message: "Please input your full name!", }]}>
                                    <Input className='antdInput' placeholder="Full Name*" name="contactPerson" />
                                </Form.Item>
                                <Form.Item className="username" name="dealerName" rules={[{ required: true, message: "Please input your company or brand name!", }]}>
                                    <Input className='antdInput' placeholder="Company or brand name*" name="dealerName" />
                                </Form.Item>
                                <Form.Item className="username" name="website" rules={[{ required: true, message: "Please input your company website!", }]}>
                                    <Input className='antdInput' placeholder="Your company website*" name="website" />
                                </Form.Item>
                                <Form.Item className="username" name="contactEmailID" rules={[{ required: true, message: "Please input your email!", }, { type: 'email', message: "Please input valid email!", }]}>
                                    <Input className='antdInput' placeholder="Email*" name="contactEmailID" />
                                </Form.Item>
                                <Form.Item className="username" name="contactNumber" rules={[{ required: true, message: "Please input your contact number!" }, { min: 10, max: 12, message: 'Phone number must be between 10 to 12 digit!' }]}>
                                    <Input className='antdInput' placeholder="Contact number*" name="contactNumber" onKeyDown={handleNumber} />
                                </Form.Item>
                                <Form.Item className='partnerSendBtn ' >
                                    <Button type="primary" style={{ width: "124px", fontWeight: "bold", backgroundColor: "rgb(248 146 31)" }} htmlType="submit" > SEND </Button>
                                </Form.Item>
                            </Form>
                            {buttonLoading && <Loading />}
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Partner