import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import authActions from '../../redux/auth/actions';
import getProfileActions from '../../redux/getProfile/actions';
import { Form, Input, Button, message, Image } from "antd";
import Swal from 'sweetalert2';
import Loading from '../Loader/Loading';
import OrderImg from '../../assets/image/Onboarding-Figure.svg'
import shopImg from '../../assets/image/online-shop.png'
import 'sweetalert2/dist/sweetalert2.css';
import globalImage from '../../assets/image/global.jpg';
import regionImage from '../../assets/image/region.jpg';
import OrderModelComponent from '../OrderModelComponent/OrderModelComponent';
import noImageFound from '../../assets/image/no-image-found.png';

function Profile(props) {
    const dispatch = useDispatch();
    const { getProfileData, changePassword, resetProfile, editProfileData, getOrderData, customerAccountDelete, getEsim } = getProfileActions;
    const { logoutRequest } = authActions;
    const profileData = useSelector((state) => state.getProfileReducer.profileDataResult);
    const orderData = useSelector((state) => state.getProfileReducer.orderDataResult);
    const loading = useSelector((state) => state.getProfileReducer.loading);
    const profileError = useSelector((state) => state.getProfileReducer.profileError);
    const profileMessage = useSelector((state) => state.getProfileReducer.profileMessage);
    const passwordMessage = useSelector((state) => state.getProfileReducer.passwordMessage);
    const buttonLoading = useSelector((state) => state.getProfileReducer.buttonLoading);
    const passwordError = useSelector((state) => state.getProfileReducer.passwordError);
    const deleteConfirmation = useSelector((state) => state.getProfileReducer.deleteConfirmation);
    const deleteFailure = useSelector((state) => state.getProfileReducer.deleteFailure);
    const esimData = useSelector((state) => state.getProfileReducer.esimData);
    const [idToken, setidToken] = useState(null);
    const [userId, setUserId] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const [name, setName] = useState('')
    const closeButtonRef = useRef(null);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: ''
    });
    const [showErrorMessage, setshowErrorMessage] = useState('')

    // const userId = profileData[0].ID;
    const [passwordModel, setPasswordModel] = useState(false);
    const [passwordData, setPasswordData] = useState({
        oldPassword: "",
        newPassword: "",
        userId: "",
    })
    const [orderModalData, setOrderModalData] = useState(new Object());

    const [form] = Form.useForm();
    useEffect(() => {
        window.scrollTo(0, 0);
        const token = (localStorage.getItem('token')) ? (localStorage.getItem('token')) : null;
        dispatch(getEsim());
        setidToken(token);
        dispatch(getProfileData());
        dispatch(getOrderData());
    }, [])

    useEffect(() => {
        if (profileData && profileData.length > 0) {
            setName(profileData[0].Name)
        }
    }, [profileData])

    const handlePassword = (e) => {
        if (profileData && profileData.length > 0) {
            const userProfile = profileData[0];
            setUserId(userProfile.ID);
        }
        // dispatch(prepareChangePasswordForm());
        const fname = e.target.name;
        const fvalue = e.target.value;
        setPasswordData(prevState => ({
            ...prevState,
            [fname]: fvalue,
            userId: userId
        }));
    }
    const handleSubmitResetPassword = () => {
        dispatch(changePassword(passwordData));
        if (passwordMessage !== null && passwordMessage !== undefined) {
            setPasswordData(prevState => ({
                ...prevState,
                oldPassword: "",
                newPassword: "",
                confirmPassword: ""
            }));
        }
    }
    const handleLogout = () => {
        const data = {
            "token": idToken,
        }
        dispatch(logoutRequest(data, props.history));
    }
    const handleClose = () => {
        dispatch(resetProfile());
    }
    const openResetPassword = () => {
        form.resetFields();
        setPasswordModel(true);
    }

    const handleCancle = () => {
        form.resetFields();
        setPasswordData(prevState => ({
            ...prevState,
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        }));
    }

    const error = (text) => {
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: `${text}`,
            // text: `${text}`,
            showConfirmButton: true,
            customClass: {
                popup: 'custom-popup-className',
                title: 'custom-title-className',
                confirmButton: 'custom-button-className',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                // Perform your desired action here when the user clicks "OK"
                handleClose();
                // Add your code here...
            }
        });
    };
    const success = (text) => {
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: `${text}`,
            // text: `${text}`,
            showConfirmButton: true,
            customClass: {
                popup: 'custom-popup-className',
                title: 'custom-title-className',
                confirmButton: 'custom-button-className',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                // Perform your desired action here when the user clicks "OK"
                if (passwordMessage !== null && passwordMessage !== undefined) {
                    form.resetFields();
                    closeButtonRef.current.click();
                }
                handleClose();
            }
        });
    };

    const errorProfile = (text) => {
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: `Login Again`,
            text: `Your session has been expired. `,
            showConfirmButton: true,
            customClass: {
                popup: 'custom-popup-className',
                title: 'custom-title-className',
                confirmButton: 'custom-button-className',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                // Perform your desired action here when the user clicks "OK"
                handleClose();
                handleLogout();
                props.history.push('/');
                // Add your code here...
            }
        });
    };

    const deleteSuccess = (text) => {
        Swal.fire(
            'Deleted!',
            `${text}`,
            'success'
        ).then((result) => {
            if (result.isConfirmed) {
                localStorage.removeItem("token");
                props.history.push('/');
                window.location.reload();
            }
        })
    }

    const deleteError = (text) => {
        Swal.fire(
            'Failed!',
            `${text}`,
            'error'
        )
    }

    const handleEdit = () => {
        if (profileData && profileData.length > 0) {
            setFormData(prevState => ({
                ...prevState,
                firstName: profileData[0].Name.split(' ')[0],
                lastName: profileData[0].Name.split(' ')[1],
                email: profileData[0].Username
            }));
            setUserId(profileData[0].ID);
        }
        setIsEditing(true);

    };

    function validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    const handleSubmit = (event) => {
        // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValidEmail = validateEmail(formData.email);
        setshowErrorMessage('')
        event.preventDefault();
        if (formData.firstName === "" || formData.lastName === "") {
            setshowErrorMessage('* Please fill out all fields to proceed!')
        } else if (formData.email === "" || formData.email === null) {
            setshowErrorMessage('* Please fill out all fields to proceed!')
        }
        else if (isValidEmail === false) {
            setshowErrorMessage('* Please enter valid email!');
        }
        else if (formData.firstName !== "" && formData.lastName !== "" && formData.email !== "" && isValidEmail === true) {
            setshowErrorMessage('')
            dispatch(editProfileData(userId, formData));
            setIsEditing(false);
        }
        // const fullName = `${formData.firstName} ${formData.lastName}`;

    };


    const handleChange = (event) => {
        setshowErrorMessage('')
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleDeleteAccount = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to delete your account!",
            // icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(customerAccountDelete());
            }
            // handleClose()
        })
    }

    const convertTimestampToCustomFormat = (timestamp) => {
        const date = new Date(timestamp);
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'short' });
        const day = ("0" + date.getDate()).slice(-2);

        const formattedTime = `${month}, ${day}, ${year}`;
        return formattedTime;
    }

    const bytesToMB = (bytes) => {
        const megabytes = bytes / (1024 * 1024);
        return megabytes + ' MB';
    }

    const convertDateStringToCustomFormat = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.toLocaleString('default', { month: 'short' });
        const day = ("0" + date.getDate()).slice(-2);

        const formattedDate = `${month}, ${day}, ${year}`;
        return formattedDate;
    }

    const showOrderDetailsModal = (order, key) => {
        setOrderModalData(order);
    }

    return (
        <>
            <OrderModelComponent order={orderModalData} />
            {passwordMessage !== null && passwordMessage !== undefined && success(passwordMessage)}
            {passwordError !== null && passwordError !== undefined && error(passwordError)}
            {profileMessage !== null && profileMessage !== undefined && success(profileMessage)}
            {profileError !== null && profileError !== undefined && errorProfile(profileError)}
            {deleteConfirmation !== undefined && deleteConfirmation !== null && deleteSuccess(deleteConfirmation)}
            {deleteFailure !== undefined && deleteFailure !== null && deleteError(deleteFailure)}
            {loading && <Loading />}
            {buttonLoading && <Loading />}
            <section className="profile-sec-1 py-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className='user-name'>
                                <div className="nav flex-column nav-pills me-3 " id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <div className="user-name-text"><h5 style={{ textTransform: "capitalize", color: "#f8921f" }}>{name}</h5></div>
                                    <hr />
                                    <button className="nav-link btn-account-f active  mg-b" id="v-pills-information-tab" data-bs-toggle="pill" data-bs-target="#v-pills-information" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Account Information</button>
                                    <hr />
                                    <button className="nav-link btn-account-f mg-b" id="v-pills-order-tab" data-bs-toggle="pill" data-bs-target="#v-pills-order" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Order</button>
                                    <hr />
                                    <button className="nav-link btn-account-f mg-b" id="view-e-sim-tab" data-bs-toggle="pill" data-bs-target="#view-e-sim" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">View E-Sim</button>
                                    <hr />
                                    <div className="logout"><Link to="#" onClick={handleLogout}>Logout &nbsp; <i className="fas fa-sign-out-alt"></i></Link></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 '>
                            <div className="tab-content" id="v-pills-tabContent">

                                <div className="tab-pane fade active show gm-p" id="v-pills-information" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                    <div className="accout-information-form">
                                        <h5>Account Information</h5>
                                    </div>
                                    {profileData && profileData.length > 0 && (
                                        <form className="form-1">
                                            <div className="mb-3 col-lg-12">
                                                <input
                                                    type="text"
                                                    className="form-control input-padding"
                                                    id="exampleInputFirstName"
                                                    name="firstName"
                                                    value={isEditing ? formData.firstName : profileData[0].Name.split(' ')[0]}
                                                    aria-describedby="emailHelp"
                                                    placeholder="First Name"
                                                    required
                                                    onChange={handleChange}
                                                    disabled={!isEditing}
                                                />
                                            </div>
                                            <div className="mb-3 col-lg-12">
                                                <input
                                                    type="text"
                                                    className="form-control input-padding"
                                                    id="exampleInputLastname"
                                                    name="lastName"
                                                    value={isEditing ? formData.lastName : profileData[0].Name.split(' ')[1]}
                                                    aria-describedby="emailHelp"
                                                    placeholder="Last Name"
                                                    required
                                                    onChange={handleChange}
                                                    disabled={!isEditing}
                                                />
                                            </div>
                                            <div className="mb-3 col-lg-12">
                                                <div className="form-group">
                                                    <input
                                                        type="email"
                                                        className="form-control input-padding"
                                                        id="exampleInputEmail1"
                                                        name="email"
                                                        value={isEditing ? formData.email : profileData[0].Username}
                                                        aria-describedby="emailHelp"
                                                        placeholder="Email"
                                                        required
                                                        onChange={handleChange}
                                                        disabled={!isEditing}
                                                    />
                                                </div>
                                                {showErrorMessage !== '' ? <p style={{ 'color': '#ff4d4f', 'fontWeight': '400' }}>{showErrorMessage}</p> : ''}
                                            </div>
                                        </form>
                                    )}
                                    {!isEditing ? (
                                        <button
                                            type="button"
                                            style={{ backgroundColor: '##f8921f' }}
                                            className="login-btn mt-4"
                                            onClick={handleEdit}
                                        >
                                            Edit
                                        </button>
                                    ) : (
                                        <button
                                            type="submit"
                                            style={{ backgroundColor: '#f8921f' }}
                                            className="login-btn mt-4"
                                            onClick={handleSubmit}
                                        >
                                            Save Changes
                                        </button>
                                    )}
                                    <div className="mb-3">
                                        <div className="form-group">
                                            <a className="change change-1" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal1" onClick={openResetPassword} style={{ width: "auto" }}>Reset Password</a>
                                        </div>
                                    </div>
                                    <div className="modal fade" id="exampleModal1" data-bs-backdrop='static' >
                                        <div className="modal-dialog modal-lg modal-dialog-centered modal-md modal-sm">
                                            <div className="modal-content1  animate">
                                                <div className="modal-header">
                                                    <button type="button" className="btn-close" onClick={handleCancle} data-bs-dismiss="modal" aria-label="Close" ref={closeButtonRef}></button>
                                                </div>
                                                <div className="modal-body1">
                                                    <div className="mb-3 contact-sale">
                                                        <h5>Reset Password</h5>
                                                    </div>
                                                    <div className="container setContainer">
                                                        <Form id="passwordForm" form={form} className="row-col" size="large" onFinish={handleSubmitResetPassword} initialValues={passwordData} >
                                                            <Form.Item className="username" name="oldPassword" rules={[{ required: true, message: "Please input old password!", }]}>
                                                                <Input.Password placeholder="Old Password" className='antdInputPassword' name="oldPassword" onChange={handlePassword} />
                                                            </Form.Item>
                                                            <Form.Item className="username" name="newPassword" rules={[{ required: true, message: "Please input new password!", }]}>
                                                                <Input.Password placeholder="New Password" className='antdInputPassword' name="newPassword" onChange={handlePassword} />
                                                            </Form.Item>
                                                            <Form.Item className="username" name="confirmPassword" rules={[
                                                                { required: true, message: "Please input confirm password" },
                                                                ({ getFieldValue }) => ({
                                                                    validator(_, value) {
                                                                        if (!value || getFieldValue('newPassword') === value) {
                                                                            return Promise.resolve();
                                                                        }
                                                                        return Promise.reject(new Error('Confirm password does not match!'));
                                                                    },
                                                                })]}>
                                                                <Input.Password placeholder="Confirm Password" className='antdInputPassword' name="confirmPassword" onChange={handlePassword} />
                                                            </Form.Item>
                                                            <Form.Item  >
                                                                <Button type="primary" style={{ width: "100%", backgroundColor: "#f8921f " }} htmlType="submit" > Save Changes</Button>
                                                            </Form.Item>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="my-5" />
                                    <h2 className="dle-text">Delete Account</h2>
                                    <p>You can delete your account permanently.<br /> *Note that this
                                        process can take a while and can't be undone after
                                        completion.</p>
                                    <button type="submit" className="login-btn w-50 del-bg" onClick={handleDeleteAccount}>Delete Account</button>
                                </div>

                                <div className="tab-pane fade gm-p" id="v-pills-order" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                    <h5>Order</h5>
                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                        <div className='order-details'>
                                            {orderData && orderData.length > 0 ? orderData.map((data, index) => {
                                                console.log(data);
                                                const newData = typeof data.OrderDetailsData
                                                if (newData === 'string') {
                                                    data.OrderDetailsData = data.OrderDetailsData && JSON.parse(data.OrderDetailsData)
                                                }
                                                const orderDetails = data.OrderDetailsData
                                                const imagesvg = `${process.env.REACT_APP_BASEURL}/planimage/${orderDetails.ImageUrl}`;
                                                return (<div className='col order-show' key={index}>
                                                    <Link to={`/plan-details/${orderDetails.PlanID}`}> <span className='img-flag-order'>
                                                        {/* <img src={imagesvg} alt="" /> */}
                                                        <Image width={131} fallback={noImageFound} preview={false} src={imagesvg} alt="" />
                                                    </span></Link>
                                                    <span className='order-plan'><ul className='mb-2' style={{ cursor: 'pointer' }} data-bs-toggle="modal" data-bs-target={`#orderDetailModal`} onClick={() => showOrderDetailsModal(data, index)}>
                                                        <li className='plan-name'>{orderDetails.PlanName}</li>
                                                        <li className='validity'>{orderDetails.PlanData} {orderDetails.PlanDays} </li>
                                                        <li className='price'>${orderDetails.PlanPrice}</li>
                                                        <li className='price'><strong>Order Id </strong>{data.OrderNumber} </li>
                                                        <li className='price'>Order Date : {convertDateStringToCustomFormat(data.OrderDate)}</li>
                                                    </ul></span>
                                                    {
                                                        data.ErrorCode == null || data.ErrorCode == "" ? <p className='pro-status' style={{ 'backgroundColor': `${data.OrderStatus === 'Pending' ? '#848b91' : data.OrderStatus === 'Processing' ? '#ff8707' : data.OrderStatus === 'Complete' ? '#29ad4a' : '#ff4d4f'}` }}>{data.OrderStatus}</p> :
                                                            <p className='pro-status' style={{ 'backgroundColor': '#848b91' }}>Payment Cancelled</p>
                                                    }

                                                </div>
                                                )
                                            }) : <div className="row">
                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                    <img src={OrderImg} className="img-fluid" alt="..." />
                                                </div>
                                                <div className="col-lg-8 col-md-6 col-sm-12 order-yet">
                                                    <h6>You don't have any orders yet.</h6>
                                                    <p>When you purchase an eSIM or top-Up package, You'll See Your Order Details Here.</p>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="tab-pane fade gm-p" id="view-e-sim" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                    <h4>View E-Sim</h4>
                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                        <div className='order-details'>

                                            {esimData && esimData.length > 0 ? esimData.map((item, index) => {
                                                return (
                                                    <div class="col order-show" key={index}>
                                                        <span class="order-plan">
                                                            <ul class="mb-2" style={styles.esimContainer}>
                                                                <li class="plan-name" style={styles.esimHeader}>ICCID : {item.ICCID}</li>
                                                                <li class="validity">Order No. : {item.OrderNumber}</li>
                                                                <li class="price" style={styles.esimLeft}>Order Date : {convertDateStringToCustomFormat(item.OrderDate)}</li>
                                                                <li class="price">Activation : {item.simPackageData.windowActivationStart ? convertTimestampToCustomFormat(item.simPackageData.windowActivationStart) : 'NA'}</li>
                                                                <li class="price" style={styles.esimLeft}>Expire : {item.simPackageData.windowActivationEnd ? convertTimestampToCustomFormat(item.simPackageData.windowActivationEnd) : 'NA'}</li>
                                                                <li class="price">Data Usage : {item.simPackageData.dataUsageRemainingInBytes ? bytesToMB(item.simPackageData.dataUsageRemainingInBytes) : 'NA'}</li>
                                                            </ul>
                                                        </span>
                                                    </div>

                                                );
                                            }) : (
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                                        <img src={OrderImg} className="img-fluid" alt="..." />
                                                    </div>
                                                    <div className="col-lg-8 col-md-6 col-sm-12 order-yet">
                                                        <h6>You don't have any esim yet.</h6>
                                                        <p>When you purchase an eSIM or top-Up package, You'll See Your Order Details Here.</p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const styles = {
    thead: {
        textWrap: "nowrap",
    },
    esimContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridGap: '7px',

    },
    esimHeader: {
        gridColumn: '1/3',
    },
    esimLeft: {
        paddingLeft: '85px',
    }
};

export default Profile