const authActions = {
    SIGNUP_REQUEST: 'SIGNUP_REQUEST',
    SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
    SIGNUP_FAILURE: 'SIGNUP_FAILURE',
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILED: "LOGIN_FAILED",
    LOGOUT_REQUEST: "LOGOUT_REQUEST",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
    LOGOUT_FAILED: "LOGOUT_FAILED",
    VERIFY_EMAIL_ON_FORGET_PASSOWRD: "VERIFY_EMAIL_ON_FORGET_PASSOWRD",
    VERIFY_EMAIL_ON_FORGET_PASSOWRD_SUCCESS: "VERIFY_EMAIL_ON_FORGET_PASSOWRD_SUCCESS",
    VERIFY_EMAIL_ON_FORGET_PASSWORD_FAILED: "VERIFY_EMAIL_ON_FORGET_PASSWORD_FAILED",
    RESET_FORGET_PASSWORD: "RESET_FORGET_PASSWORD",
    RESET_FORGET_PASSWORD_SUCCESS: "RESET_FORGET_PASSWORD_SUCCESS",
    RESET_FORGET_PASSWORD_FAILED: "RESET_FORGET_PASSWORD_FAILED",
    RESET_AUTH_ERROR:"RESET_AUTH_ERROR",
    signUpRequest: (data) => ({
        type: authActions.SIGNUP_REQUEST,
        payload: { data },
    }),
    signUpSuccess: (message) => ({
        type: authActions.SIGNUP_SUCCESS,
        message
    }),
    signUpFailure: (error) => ({
        type: authActions.SIGNUP_FAILURE,
        error
    }),
    loginRequest: (data, history) => ({
        type: authActions.LOGIN_REQUEST,
        payload: { data, history },
    }),
    loginSuccess: (token) => ({
        type: authActions.LOGIN_SUCCESS,
        token
    }),
    loginFailed: (error) => ({
        type: authActions.LOGIN_FAILED,
        error
    }),
    logoutRequest: (token, history) => ({
        type: authActions.LOGOUT_REQUEST,
        payload: { token, history },
    }),
    logoutSuccess: () => ({
        type: authActions.LOGOUT_SUCCESS,
    }),
    logoutFailed: () => ({
        type: authActions.LOGOUT_FAILED,
    }),
    verifyEmail: (data) => ({
        type: authActions.VERIFY_EMAIL_ON_FORGET_PASSOWRD,
        payload: { data },
    }),
    verifyEmailSuccess: (message) => ({
        type: authActions.VERIFY_EMAIL_ON_FORGET_PASSOWRD_SUCCESS,
        message
    }),
    verifyEmailFailed: (error) => ({
        type: authActions.VERIFY_EMAIL_ON_FORGET_PASSWORD_FAILED,
        error
    }),
    resetForgetPassword: (data) => ({
        type: authActions.RESET_FORGET_PASSWORD,
        payload: { data },
    }),
    resetForgetPasswordSuccess: (message) => ({
        type: authActions.RESET_FORGET_PASSWORD_SUCCESS,
        message
    }),
    resetForgetPasswordFailed: (error) => ({
        type: authActions.RESET_FORGET_PASSWORD_FAILED,
        error
    }),
    resetAuthError:()=>({
        type:authActions.RESET_AUTH_ERROR
    })

};
export default authActions;