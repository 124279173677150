import actions from "./actions";

const initState = {
    searchLoading: false,
    error: null,
    loading: false,
    buttonLoading: false,
    searchPlanResult: [],
    searchPlanError: null,
    compatibleMesssage: null,
    tokenMessage: null,
    tokenError: null,
    compatibleError: null,
    imeiNumber: null,
    planDataResult: [],
    planDetailsResult: [],
    bannerResult: [],
    imeiStatus: null,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_SEARCH_PLAN:
            return {
                ...state,
                searchLoading: true,
                searchPlanError: null
            };
        case actions.GET_SEARCH_PLAN_SUCCESS:
            return {
                ...state,
                searchLoading: false,
                searchCountryResult: action.searchCountryResult,
                searchRegionResult: action.searchRegionResult,
                searchPlanError: null
            };
        case actions.GET_SEARCH_PLAN_FAILED:
            return {
                ...state,
                loading: false,
                searchPlanError: action.error,
                error: action.error
            };
        case actions.VALIDATE_TOKEN:
            return {
                ...state,
                buttonLoading: true,
            };
        case actions.VALIDATE_TOKEN_SUCCESS:
            return {
                ...state,
                buttonLoading: false,
                tokenMessage: action.tokenMessage

            };
        case actions.VALIDATE_TOKEN_FAILED:
            return {
                ...state,
                buttonLoading: false,
                tokenError: action.tokenError
            };
        case actions.GET_PLAN_DATA:
            return {
                ...state,
                searchLoading: true,
                searchPlanError: null
            };
        case actions.GET_PLAN_DATA_SUCCESS:
            return {
                ...state,
                searchLoading: false,
                searchPlanError: null,
                planDataResult: action.planDataResult
            };
        case actions.GET_PLAN_DATA_FAILED:
            return {
                ...state,
                loading: false,
                searchLoading: false,
                searchPlanError: action.error,
                error: action.error
            };
        case actions.GET_PLAN_DATA_BEST_SELLER:
            return {
                ...state,
                searchLoading: true,
                searchPlanError: null
            };
        case actions.GET_PLAN_DATA_BEST_SELLER_SUCCESS:
            return {
                ...state,
                searchLoading: false,
                searchPlanError: null,
                planDataResult: action.planDataResult
            };
        case actions.GET_PLAN_DATA_BEST_SELLER_FAILED:
            return {
                ...state,
                loading: false,
                searchLoading: false,
                searchPlanError: action.error,
                error: action.error
            };
        case actions.CHECK_COMPATIBLE_STATUS:
            return {
                ...state,
                loading: true,
                buttonLoading: true,
            };
        case actions.CHECK_COMPATIBLE_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                compatibleMesssage: action.compatibleMesssage,
                imeiNumber: action.imeiNumber,
                imeiStatus: JSON.parse(action.response).ESIM,
                compatibleError: null,
            };
        case actions.CHECK_COMPATIBLE_STATUS_FAILED:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                compatibleMesssage: null,
                searchLoading: false,
                imeiNumber: action.imeiNumber,
                compatibleError: action.compatibleError,
                error: action.error
            };
        case actions.RESET_HOME_PAGE:
            return {
                ...state,
                loading: false,
                compatibleMesssage: null,
                compatibleError: null,
                imeiNumber: null,
                buttonLoading: false,
                error: null,
                tokenMessage: null,
                tokenError: null
            };
        case actions.GET_PLAN_BY_REFERENCE_URL:
            return {
                ...state,
                loading: true,
                buttonLoading: false,
                compatibleMesssage: null,
                planDetailsResult:[]
            };
        case actions.GET_PLAN_BY_REFERENCE_URL_SUCCESSFULL:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                compatibleMesssage: null,
                planDetailsResult: action.planDetailsResult
            };
        case actions.GET_PLAN_BY_REFERENCE_URL_FAILED:
            return {
                ...state,
                loading: false,
                buttonLoading: false,
                searchLoading: false,
                compatibleMesssage: null,
                planError: action.error,
                error: action.error
            };
        case actions.GET_BANNER_DATA:
            return {
                ...state,
                loading: true,
            };
        case actions.GET_BANNER_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                bannerResult: action.bannerResult,
            };
        case actions.GET_BANNER_DATA_FAILED:
            return {
                ...state,
                loading: false,
                error: action.error,
            };

        default:
            return state;
    }
}