import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import regionSagas from './region/saga';
import getProfileSagas from './getProfile/saga';
import partnerSagas from './partner/saga';
import homeSagas from './home/saga';
import contactUsSagas from './contactUs/saga';
import checkOutSagas from './checkOut/saga';
import testimonialsSagas from './testimonials/saga';

export default function* rootSaga() {
        yield all([
                authSagas(),
                getProfileSagas(),
                partnerSagas(),
                regionSagas(),
                homeSagas(),
                contactUsSagas(),
                checkOutSagas(),
                testimonialsSagas()
        ])
}