import actions from "./actions";

const initState = {
    loading: false,
    dealerError: null,
    dealerMessage: null,
    buttonLoading: false,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.ADD_DEALER:
            return {
                ...state,
                loading: false,
                dealerError: null,
                dealerMessage: null,
                buttonLoading: true,
            };
        case actions.ADD_DEALER_SUCCESS:
            return {
                ...state,
                loading: false,
                dealerError: null,
                dealerMessage: action.dealerMessage,
                buttonLoading: false,

            };
        case actions.ADD_DEALER_FAILED:
            return {
                ...state,
                loading: false,
                dealerError: action.dealerError,
                dealerMessage: null,
                buttonLoading: false,

            };
        case actions.RESET_DEALER:
            return {
                ...state,
                loading: false,
                dealerError: null,
                dealerMessage: null,
                buttonLoading: false,
            };
        default:
            return state;
    }
}
