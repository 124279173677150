import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import logo from '../assets/image/virtuzo-logo.png';
import jwt_decode from "jwt-decode";
function NavBar() {
  const [token, setToken] = React.useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  React.useEffect(() => {
    const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;
    if (token) {
      let decodedToken = jwt_decode(token);
      // console.log(decodedToken);
      let currentDate = new Date();
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        localStorage.removeItem("token");
        setToken('');
      } else {
        setToken(token);
      }
    } else {
      localStorage.removeItem("token");
      setToken('');
    }
    setToken(token);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <nav className="navbar navbar-expand-lg  nav-bar-color">
      <Link className="navbar-brand" to="/"><img src={logo} alt={logo} width="280px"></img></Link>
      <button className={`navbar-toggler hamburger ${isMenuOpen ? 'is-active' : ''}`} id="hamburger-1" type="button" data-bs-toggle="collapse" onClick={toggleMenu}
        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <span className="toggler-icon line"></span>
        <span className="toggler-icon line"></span>
        <span className="toggler-icon line"></span>
      </button>
      <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0 margin-l">
          <li className="nav-item" id="nav-item-h" onClick={toggleMenu}>
            <NavLink className="nav-link nav-color" exact to="/" aria-current="page" activeclassname="active"><i className="fas fa-home navIcon"></i>
              Home</NavLink>
          </li>
          <li className="nav-item" id="nav-item-h" onClick={toggleMenu}>
            <NavLink className="nav-link nav-color" to="/shop" aria-current="page" activeclassname="active"><i className="fas fa-shopping-bag navIcon"></i>
              Shop</NavLink>
          </li>

          <li className="nav-item" id="nav-item-h" onClick={toggleMenu}>
            <NavLink className="nav-link nav-color " to="/partner" aria-current="page" activeclassname="active"><i className="fas fa-user-friends navIcon"></i>
              Partner</NavLink>
          </li>

          <li className="nav-item" id="nav-item-h" onClick={toggleMenu}>
            <NavLink className="nav-link nav-color " to="/contact-us" aria-current="page" activeclassname="active"><i className="fas fa-id-card navIcon"></i>
              Contact us </NavLink>
          </li>
          {token === '' || token === null ?
            <li className="nav-item" id="nav-item-h" onClick={toggleMenu}> 
              <NavLink className="nav-link nav-color" aria-current="page" to="/login" activeclassname="active"><i className="fas fa-user navIcon"></i> Login</NavLink>
            </li> :
            <li className="nav-item" id="nav-item-h" onClick={toggleMenu}>
              <NavLink className="nav-link nav-color" aria-current="page" to="/profile" activeclassname="active"><i className="fas fa-user navIcon"></i> Profile</NavLink>
            </li>
          }

        </ul>
      </div>
    </nav>
  )
}

export default NavBar