const actions = {
    ADD_DEALER: "ADD_DEALER",
    ADD_DEALER_SUCCESS: "ADD_DEALER_SUCCESS",
    ADD_DEALER_FAILED: "ADD_DEALER_FAILED",
    RESET_DEALER: "RESET_DEALER",

    addDealerData: (data) => ({
        type: actions.ADD_DEALER,
        payload: { data }
    }),
    addDealerDataSuccess: (dealerMessage) => ({
        type: actions.ADD_DEALER_SUCCESS,
        dealerMessage
    }),
    addDealerDataFailed: (dealerError) => ({
        type: actions.ADD_DEALER_FAILED,
        dealerError
    }),
    resetDealer: () => ({
        type: actions.RESET_DEALER,
    })
}
export default actions;