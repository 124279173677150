import React, { useState } from 'react'
import Carousel from "react-grid-carousel";
import noPlanFoundImg from "../../assets/image/no-data-found.png";
import noImageFound from '../../assets/image/no-image-found.png';
import { Link, useHistory } from "react-router-dom";
import { Image } from "antd";
function CarouselComponent({ planDataResult, planData, searchLoading, loading, heading }) {
    const history = useHistory();
    const [dummyData, setDummyData] = useState([1, 2, 3, 4, 5]);
    const getCarouselConfig = () => {
        if (window.innerWidth <= 1200) {
            return tabletConfig; // For tablets
        } else {
            // You can add additional configurations for larger screens if needed
            return deskConfig; // Default to tablet configuration
        }
    };

    const tabletConfig = {
        cols: 2,
        rows: 1,
        gap: 10,
        loop: true,
        autoplay: 1000,
    };

    const deskConfig = {
        cols: 4,
        rows: 1,
        gap: 10,
        loop: true,
        autoplay: 1000,
    };

    const carouselConfig = getCarouselConfig();

    const NavigateToPlanDetailPage = (referenceURL) => {
        history.push(`/plan-details/${referenceURL}`);
    }
    return (
        <section className="sec-3 p-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="row">
                            <div className="w-50">
                                <h5 className="reg-heading">{heading}</h5>
                            </div>
                            <div className="w-50 text-end">
                                {planDataResult && planDataResult.length > 0 ? (
                                    <Link className="btn-more-product" to="/shop">
                                        {" "}
                                        <span>More plans</span>
                                    </Link>
                                ) : null}
                            </div>
                        </div>
                        <Carousel cols={carouselConfig.cols} rows={carouselConfig.rows} gap={carouselConfig.gap} loop={carouselConfig.loop}>
                            {planData && planData.length > 0 ?
                                planData.map((plan, index) => {
                                    const imagesvg = `${process.env.REACT_APP_BASEURL}/planimage/${plan.ImageUrl}`;
                                    return (
                                        <Carousel.Item key={index}>
                                            <div className="col">
                                                <div className="card" >
                                                    {plan.FeaturedPlan === 1 && <h1 className="ribbon" style={{ backgroundColor: "#B70404" }}>Featured</h1>}
                                                    {plan.BestSeller === 1 && <h1 className="ribbon">Best Seller</h1>}
                                                    <div className="image-container" onClick={() => NavigateToPlanDetailPage(plan.ReferenceURL)}>
                                                        <Image preview={false} fallback={noImageFound} src={imagesvg} />
                                                    </div>
                                                    <div className="card-body card-body-1">
                                                        <ul className="ul-margin">
                                                            <li className="bahamas" onClick={() => NavigateToPlanDetailPage(plan.ReferenceURL)}>{plan.PlanName}</li>
                                                            <li className="nrth-amrica">
                                                                {plan.PlanDataValue +
                                                                    " " +
                                                                    plan.PlanDaysValue}
                                                            </li>
                                                        </ul>
                                                        <div className="card-text-2">
                                                            <span className="day-5gb">COVERAGE</span>
                                                            <span
                                                                className="dolor-coverage-Country-homePage india-coverage"
                                                                data-bs-toggle="modal"
                                                                data-bs-target={`#staticModel${plan.ID}`}
                                                            >
                                                                {plan.CountryISO2 &&
                                                                    plan.CountryISO2.length > 0 &&
                                                                    plan.CountryISO2.length + " Countries"}
                                                            </span>
                                                        </div>
                                                        <div className="price-text">
                                                            <span className="dolor">
                                                                <sup>$</sup>
                                                                {plan.PlanPrice}
                                                            </span>
                                                            <Link
                                                                to={`/plan-details/${plan.ReferenceURL}`}
                                                                className="shop"
                                                            >
                                                                <i className="fas fa-shopping-cart"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* CountryISO3 Model   */}
                                        </Carousel.Item>
                                    );
                                })
                                : loading || searchLoading ?
                                    dummyData.map((item, index) => {
                                        return (
                                            <Carousel.Item key={index}>
                                                <div className="card is-loading" id="card-ab">
                                                    <div className="image"></div>
                                                    <div className="content">
                                                        <h2></h2>
                                                        <p className="p-2"></p>
                                                        <p></p>
                                                        <p className="p-2"></p>
                                                    </div>
                                                </div>
                                            </Carousel.Item>
                                        )
                                    }) : (
                                        <Carousel.Item>
                                            <div className="col-lg-12 col-md-12 col-sm-12 pt-4 d-flex align-items-center justify-content-center">
                                                <div className="noPlanFoundImg">
                                                    <img src={noPlanFoundImg} alt="" />
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                    )}
                        </Carousel>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CarouselComponent