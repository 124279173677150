import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData } from '../../helpers/fackBackend_Helper';

function* addContactDetailsResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/createInquiry', data);
        if (response.response.Status) {
            yield put(
                actions.addContactDetailsSuccess(
                    response.response.Message
                ));
        } else {
            yield put(actions.addContactDetailsFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.addContactDetailsFailed(error));
    }
}


export default function* rootSaga() {
    yield all([takeEvery(actions.ADD_CONTACT_DETAILS, addContactDetailsResponse)]);
}