import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Alert } from "antd";
import contactActions from '../../redux/contactUs/actions';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import Loading from '../Loader/Loading';
import 'sweetalert2/dist/sweetalert2.css';
const { TextArea } = Input;
function Contact() {
    const dispatch = useDispatch();
    const { addContactDetails, resetContactDetails } = contactActions;
    const buttonLoading = useSelector((state) => state.contactUsReducer.buttonLoading);
    const successMessage = useSelector((state) => state.contactUsReducer.contactDetailsMessage);
    const errorMessage = useSelector((state) => state.contactUsReducer.contactDetailsError);

    const [form] = Form.useForm();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const handleSubmit = (value) => {
        dispatch(addContactDetails(value));
        setTimeout(() => {
            handleClose();
        }, 10000);


    };
    const handleClose = () => {
        dispatch(resetContactDetails());
    }

    const handleNumber = (e) => {
        if (((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) && e.keyCode != 8) {
            e.preventDefault();
        }
    }

    const error = (text) => {
        Swal.fire({
            position: 'center',
            icon: 'error',
            title: `${text}`,
            // text: `${text}`,
            showConfirmButton: true,
            customClass: {
                popup: 'custom-popup-class',
                title: 'custom-title-class',
                confirmButton: 'custom-button-class',
            },
        }).then((result) => {
            if (result.isConfirmed) {

                // Perform your desired action here when the user clicks "OK"
                handleClose();

                // Add your code here...
            }
        });
    };

    const success = (text) => {
        Swal.fire({
            position: 'center',
            icon: 'success',
            title: `Thank You`,
            text: `We've Received Your Message`,
            showConfirmButton: true,
            customClass: {
                popup: 'custom-popup-class',
                title: 'custom-title-class',
                confirmButton: 'custom-button-class',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                if (successMessage !== null) {
                    form.resetFields();
                }
                // Perform your desired action here when the user clicks "OK"
                handleClose();
                // Add your code here...
            }
        });
    };

    return (
        <>
            {/* section 1 */}

            {successMessage !== null && successMessage !== undefined && success(successMessage)}
            {errorMessage !== null && errorMessage !== undefined && error(errorMessage)}
            <section className="check-sec py-5">
                <div className="container">
                    <div className="row py-4 justify-content-around">
                        <div className="col-lg-5 col-md-6 col-sm-12">
                            <h6 className="cont-text">Our team is ready to assist you!</h6>
                            <Form form={form} className="row-col" size="large" onFinish={handleSubmit}>
                                <Form.Item className="username" name="name" rules={[{ required: true, message: "Please input your full name!", }]}>
                                    <Input className='antdInputContact contact-f-c' placeholder="Full Name*" name="name" />
                                </Form.Item>
                                <Form.Item className="username " name="email" rules={[{ required: true, message: "Please input email!" }, { type: 'email', message: "Please input valid email!", }]}>
                                    <Input className='antdInputContact contact-f-c' placeholder="Email*" name="email" />
                                </Form.Item>
                                <Form.Item className="username" name="contact" rules={[{ required: true, message: "Please input your phone number!" }, { min: 10, max: 12, message: 'Phone number must be between 10 to 12 digit!' }]}>
                                    <Input className='antdInputContact contact-f-c' placeholder="Phone Number*" name="contact" onKeyDown={handleNumber} />
                                </Form.Item>
                                <Form.Item className="username" name="subject" rules={[{ required: true, message: "Please input your subject!", }]}>
                                    <Input className='antdInputContact contact-f-c' placeholder="Subject*" name="subject" />
                                </Form.Item>
                                <Form.Item className="username  " name="message" rules={[{ required: true, message: "Please input your message!", }]}>
                                    {/* <Input className='antdInputContact' placeholder="Message*" name="message" /> */}
                                    <TextArea rows={4} placeholder="Message*" name="message" className="contact-f-c" />
                                </Form.Item>
                                {/* {successMessage &&
                                    <Alert message={`${successMessage} Check You Email.`} type="success" showIcon />
                                    // <p style={{ color: "green" }}>{`${successMessage} Check You Email.`}</p>
                                } */}
                                <Form.Item  >
                                    <Button type="primary" style={{ width: "100%", fontWeight: "bold", backgroundColor: "rgb(248 146 31)" }} htmlType="submit" > SEND </Button>
                                </Form.Item>
                            </Form>
                            {buttonLoading && <Loading />}
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12">
                            <div className="contact-card">
                                <div className="col text-message">
                                    <p>Contact us to learn more about eSIM Solutions for Your local and global connectivity.</p>
                                </div>

                                <div className="col text-contact-detail">
                                    <h6>Contact Details:</h6>
                                    <ul>
                                        <li className="contact-item"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
                                            <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                                            <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                        </svg> <a className='contact-link' href="#">10878 Westheimer Rd, #208 Houston,Tx 77042 USA</a></li>

                                        <li className="contact-item"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                        </svg> <a className='contact-link' href="mailto:contact@esimsource.com">  contact@esimsource.com </a></li>

                                        <li className="contact-item"> <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M256 48C141.1 48 48 141.1 48 256v40c0 13.3-10.7 24-24 24s-24-10.7-24-24V256C0 114.6 114.6 0 256 0S512 114.6 512 256V400.1c0 48.6-39.4 88-88.1 88L313.6 488c-8.3 14.3-23.8 24-41.6 24H240c-26.5 0-48-21.5-48-48s21.5-48 48-48h32c17.8 0 33.3 9.7 41.6 24l110.4 .1c22.1 0 40-17.9 40-40V256c0-114.9-93.1-208-208-208zM144 208h16c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H144c-35.3 0-64-28.7-64-64V272c0-35.3 28.7-64 64-64zm224 0c35.3 0 64 28.7 64 64v48c0 35.3-28.7 64-64 64H352c-17.7 0-32-14.3-32-32V240c0-17.7 14.3-32 32-32h16z" /></svg>
                                            <a className='contact-link' href="mailto:support@esimsource.com"> support@esimsource.com </a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
            {/* section 1 */}

        </>
    )
}

export default Contact