import actions from "./actions";

const initState = {
    profileDataResult: [],
    orderDataResult: [],
    loading: false,
    profileError: null,
    profileMessage: null,
    buttonLoading: false,
    passwordMessage: null,
    passwordError: null,
    deleteConfirmation: null,
    deleteFailure: null,
    // This is for E-sim section
    esimData : [],
    esimLoading : false,
    esimError: null,
    esimMessage: null,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_POFILE_DATA:
            return {
                ...state,
                loading: true,
                profileError: null,
                profileMessage: null,
                buttonLoading: false,
            };
        case actions.GET_POFILE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                profileError: null,
                profileMessage: null,
                profileDataResult: action.profileDataResult,
                buttonLoading: false,

            };
        case actions.GET_POFILE_DATA_FAILED:
            return {
                ...state,
                loading: false,
                profileError: action.profileError,
                profileMessage: null,
                buttonLoading: false,

            };
        case actions.EDIT_PROFILE_DATA:
            return {
                ...state,
                loading: true,
                profileError: null,
                profileMessage: null,
                buttonLoading: true,

            };
        case actions.EDIT_PROFILE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                profileError: null,
                profileDataResult: action.profileDataResult,
                profileMessage: action.profileMessage,
                buttonLoading: false,
            };
        case actions.EDIT_PROFILE_DATA_FAILED:
            return {
                ...state,
                loading: false,
                profileMessage: null,
                profileError: action.profileError,
                buttonLoading: false,
            };
        case actions.CHANGE_PASSWORD:
            return {
                ...state,
                loading: false,
                passwordMessage: null,
                passwordError: null,
                buttonLoading: true,
            };
        case actions.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                passwordMessage: action.passwordMessage,
                passwordError: null,
                buttonLoading: false,
            };
        case actions.CHANGE_PASSWORD_FAILED:
            return {
                ...state,
                loading: false,
                passwordMessage: null,
                passwordError: action.passwordError,
                buttonLoading: false,
            };
        case actions.GET_ORDER_DATA:
            return {
                ...state,
                loading: true,
            };
        case actions.GET_ORDER_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                orderDataResult: action.orderDataResult,
            };
        case actions.GET_ORDER_DATA_FAILED:
            return {
                ...state,
                profileError: action.Error,
            };
        case actions.RESET_PROFILE:
            return {
                ...state,
                loading: false,
                profileMessage: null,
                profileError: null,
                buttonLoading: false,
                passwordMessage: null,
                passwordError: null,
                deleteConfirmation: null,
                deleteFailure: null
            };
        case actions.DELETE_CUSTOMER_ACCOUNT:
            return {
                ...state,
                loading: false,
            };
        case actions.DELETE_CUSTOMER_ACCOUNT_SUCCESSFULLY:
            return {
                ...state,
                loading: false,
                deleteConfirmation: action.message,
            };
        case actions.DELETE_CUSTOMER_ACCOUNT_FAILED:
            return {
                ...state,
                loading: false,
                deleteFailure: action.Error,
            };
        // This is for E-Sim Section
        case actions.GET_ESIM:
            return {
                ...state,
                esimLoading: true,
                loading: true,
                esimError: null,
                esimMessage: null,
            };
        case actions.GET_ESIM_SUCCESS:
            // console.log(action.esimDataResult);
            return {
                ...state,
                esimLoading: false,
                loading: false,
                esimError: null,
                esimMessage: null,
                esimData: action.esimDataResult,

            };
        case actions.GET_ESIM_FAILED:
            return {
                ...state,
                esimLoading: false,
                loading: false,
                esimError: action.profileError,
                esimMessage: null,

            };
        default:
            return state;
    }
}
