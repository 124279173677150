import actions from "./actions";

const initState = {
    loading: false,
    orderMessage: null,
    orderError: null,
    OrderId: null,
    OrderNumber: null,
    orderData: [],
    buttonLoading: false,
    // Payment gateway
    paymentGatewayLoading: false,
    paymentGatewayMessage: null,
    paymentGatewayError: null,
    paymentGateway: [],
    // for website checkout
    paymentLoader: false,
    //Coupon Apply
    applyCouponResult: [],
    applyCouponError: null,
    applyCouponMessage: null,
    applyCouponLoading: false,
    isCouponApplied: false,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.CREATE_ORDER_REQUEST:
            return {
                ...state,
                loading: true,
                orderError: null,
                orderMessage: null,
                buttonLoading: true,
            };
        case actions.CREATE_ORDER_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                orderError: null,
                orderMessage: action.orderMessage,
                OrderId: action.OrderId,
                OrderNumber: action.OrderNumber,
                buttonLoading: false,

            };
        case actions.CREATE_ORDER_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                orderError: action.orderError,
                orderMessage: null,
                buttonLoading: false,

            };
        case actions.SEND_ORDER_DATA_REQUEST:
            return {
                ...state,
                loading: true,
                orderError: null,
                orderMessage: null,
                buttonLoading: true,
                paymentLoader: true,
            };
        case actions.SEND_ORDER_DATA_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                orderError: null,
                orderData: action.orderData,
                buttonLoading: false,
                paymentLoader: false,

            };
        case actions.SEND_ORDER_DATA_REQUEST_FAILED:
            return {
                ...state,
                loading: false,
                orderData: action.orderData,
                orderMessage: null,
                buttonLoading: false,
                paymentLoader: false,

            };
        case actions.RESET_CREATE_ORDER_REQUEST:
            return {
                ...state,
                loading: false,
                orderError: null,
                orderMessage: null,
                buttonLoading: false,
                orderData: [],
            };

        case actions.GET_PAYMENT_GATEWAY:
            return {
                ...state,
                paymentGatewayLoading: true,
                paymentGatewayError: null,
                paymentGatewayMessage: null,
            };
        case actions.GET_PAYMENT_GATEWAY_SUCCESS:
            return {
                ...state,
                paymentGatewayLoading: false,
                paymentGatewayError: null,
                paymentGateway: action.paymentGateway,
                paymentGatewayMessage: 'Get success'
            };
        case actions.GET_PAYMENT_GATEWAY_FAILED:
            return {
                ...state,
                paymentGatewayLoading: false,
                paymentGatewayMessage: null,
                paymentGatewayError: action.Message
            };
        case actions.COUPON_APPLY:
            return {
                ...state,
                applyCouponError: null,
                applyCouponMessage: null,
                applyCouponLoading: true,
                isCouponApplied: false,
            };
        case actions.COUPON_APPLY_SUCCESS:
            return {
                ...state,
                applyCouponError: null,
                applyCouponResult: action.result,
                applyCouponMessage: action.message,
                applyCouponLoading: false,
                isCouponApplied: true,
            };
        case actions.COUPON_APPLY_FAILED:
            return {
                ...state,
                applyCouponError: action.error,
                applyCouponResult: [],
                applyCouponMessage: null,
                applyCouponLoading: false,
                isCouponApplied: false,
            };

            case actions.COUPON_RESET:
            return {
                ...state,
                applyCouponError: null,
                applyCouponResult: [],
                applyCouponMessage: null,
                applyCouponLoading: false,
                isCouponApplied: false,
            };

            case actions.COUPON_RESET_MESSAGE:
                return {
                    ...state,
                    applyCouponError: null,
                    applyCouponMessage: null,
                    applyCouponLoading: false,
                };

        default:
            return state;
    }
}
