import React from 'react'
import './error.css'
import errorImg from '../../assets/image/error-img.png'

function Error() {

    const Reload = () => {
        window.location.reload();
    }
    return (
        <section className="page_404">

            <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                <img src={errorImg} className="img-fluid" id="zoom-an" alt="..." />

                <h1 className="error-text">Whoops, We can't seem to find the resource you're looking for.</h1>
                <p className="text-error-two">Please check that the Web site address is spelled correctly.</p>
                <div className="btn1" onClick={Reload}>
                    <a className="error500" href="#">Try Again</a>
                </div>
            </div>

        </section>
    )
}

export default Error
