import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { getData } from '../../helpers/fackBackend_Helper';

function* getTestimonialsDataResponse() {
    try {
        const response = yield call(getData, '/testimonials');
        if (response.response.Status) {
            yield put(
                actions.getTestimonialsDataSuccess(
                    response.response.Data
                )
            );
        } else {
            yield put(actions.getTestimonialsDataFailed());
        }
    } catch (error) {
        yield put(actions.getTestimonialsDataFailed());
    }
}


export default function* rootSaga() {
    yield all([takeEvery(actions.GET_TESTIMONIALS_DATA, getTestimonialsDataResponse)]);
}