import actions from "./actions";

const initState = {
    loading: false,
    contactDetailsError: null,
    contactDetailsMessage: null,
    buttonLoading: false,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.ADD_CONTACT_DETAILS:
            return {
                ...state,
                loading: false,
                contactDetailsError: null,
                contactDetailsMessage: null,
                buttonLoading: true,
            };
        case actions.ADD_CONTACT_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                contactDetailsError: null,
                contactDetailsMessage: action.contactDetailsMessage,
                buttonLoading: false,

            };
        case actions.ADD_CONTACT_DETAILS_FAILED:
            return {
                ...state,
                loading: false,
                contactDetailsError: action.contactDetailsError,
                contactDetailsMessage: null,
                buttonLoading: false,

            };
        case actions.RESET_CONTACT_DETAILS:
            return {
                ...state,
                loading: false,
                contactDetailsError: null,
                contactDetailsMessage: null,
                buttonLoading: false,
            };
        default:
            return state;
    }
}
