import { combineReducers } from "redux";
import authReducer from './auth/reducer';
import regionReducer from './region/reducer';
import getProfileReducer from './getProfile/reducer';
import partnerdataReducer from './partner/reducer';
import homeReducer from './home/reducer';
import contactUsReducer from './contactUs/reducer';
import checkOutReducer from './checkOut/reducer';
import testimonialsReducer from './testimonials/reducer';


const rootReducer = combineReducers({
    authReducer,
    regionReducer,
    getProfileReducer,
    partnerdataReducer,
    homeReducer,
    contactUsReducer,
    checkOutReducer,
    testimonialsReducer
});
export default rootReducer;