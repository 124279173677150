import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, postForgetPwd } from '../../helpers/fackBackend_Helper';

function* signUpRequestResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/customerSingUp', data);
        if (response.response.Status) {
            yield put(
                actions.signUpSuccess(
                    response.response.Message
                ));
        } else {
            yield put(actions.signUpFailure(response.response.Message));
        }
    } catch (error) {
        yield put(actions.signUpFailure(error));
    }
}

function* loginRequestResponse({ payload: { data, history } }) {
    try {
        const response = yield call(addData, '/customer_login', data);
        if (response.response.Status) {
            localStorage.setItem("token", JSON.stringify(response.response.Token));
            yield put(actions.loginSuccess(response.response.Token));
            const redirectionPathname = localStorage.getItem('redirectionPathName') !== null ? localStorage.getItem('redirectionPathName') : '/'
            console.log(redirectionPathname);
            const parts = redirectionPathname.split('/');
            if (redirectionPathname === '' || redirectionPathname === 'shop' || redirectionPathname === 'partner' || parts[0] === 'plan-details' || parts[0] === 'check-out' || redirectionPathname === 'profile' || redirectionPathname === 'privacy-policy' || redirectionPathname === 'terms-condition' || redirectionPathname === 'refund-policies') {
                history.push(redirectionPathname)
            } else {
                history.push('/');
            }
        } else {
            yield put(actions.loginFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.loginFailed(error));
    }
}

function* logoutRequestResponse({ payload: { token, history } }) {
    try {
        const response = yield call(addData, '/customer_logout', token);
        if (response.response.Status) {
            localStorage.removeItem("token");
            yield put(actions.logoutSuccess(response.response.Message));
            history.push('/login');
            window.location.reload();
        } else {
            yield put(actions.logoutFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.logoutFailed(error));
    }
}

function* verifyEmailResponse({ payload: { data } }) {
    try {
        const response = yield call(postForgetPwd, '/forgetPassword', data);
        if (response.response.Status) {
            yield put(
                actions.verifyEmailSuccess(
                    response.response.Message
                ));
        } else {
            yield put(actions.verifyEmailFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.verifyEmailFailed(error));
    }
}

function* resetForgetPasswordResponse({ payload: { data } }) {
    try {
        const response = yield call(postForgetPwd, '/resetPassword', data);
        if (response.response.Status) {
            yield put(
                actions.resetForgetPasswordSuccess(
                    response.response.Message
                ));
        } else {
            yield put(actions.resetForgetPasswordFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.resetForgetPasswordFailed(error));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.SIGNUP_REQUEST, signUpRequestResponse)]);
    yield all([takeEvery(actions.LOGIN_REQUEST, loginRequestResponse)]);
    yield all([takeEvery(actions.LOGOUT_REQUEST, logoutRequestResponse)]);
    yield all([takeEvery(actions.VERIFY_EMAIL_ON_FORGET_PASSOWRD, verifyEmailResponse)]);
    yield all([takeEvery(actions.RESET_FORGET_PASSWORD, resetForgetPasswordResponse)]);
}