import React, { useEffect, useState } from 'react';
import bannerImage from '../../assets/image/main-bg-2.jpg';
import homeActions from '../../redux/home/actions';
import { useDispatch, useSelector } from 'react-redux';

const { getBanner } = homeActions;

const HomeSlider = () => {
    const dispatch = useDispatch();
    const bannerResult = useSelector((state) => state.homeReducer.bannerResult);
    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
        dispatch(getBanner());
    }, []);

    const handleImageLoad = () => {
        setImagesLoaded(true);
    };

    return (
        <div id="carouselExampleInterval" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item carousel-ite-1 active" data-bs-interval="5000">
                    <img
                        src={bannerImage}
                        className="img-fluid d-block w-100 h-100"
                        alt="Statue of Liberty"

                    />

                </div>
                {bannerResult &&
                    bannerResult.length > 0 &&
                    bannerResult
                        .filter((banner) => banner.IsActive === 1)
                        .map((item, index) => {
                            const imageBanner = `${process.env.REACT_APP_BASEURL}/bannerImages/${item.BannerImageUrl}`;
                            return (
                                <div className="carousel-item carousel-ite-1" data-bs-interval="5000" key={index}>
                                    <img
                                        src={imageBanner}
                                        className="img-fluid d-block w-100"
                                        alt={item.BannerTitle}
                                        style={{ display: imagesLoaded ? 'block' : 'none' }}
                                        onLoad={handleImageLoad}
                                    />
                                    {!imagesLoaded && (
                                        <div className="placeholder">
                                            Loading...
                                        </div>
                                    )}
                                </div>
                            );
                        })}
            </div>
        </div>
    );
};

export default HomeSlider;
