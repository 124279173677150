import React, { useEffect, useState, useRef } from 'react';
import { Image } from 'antd';
import noImageFound from '../../assets/image/no-image-found.png';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import homeActions from '../../redux/home/actions';
import CountryFlag from 'react-country-flag';
import Loading from '../Loader/Loading';
import NetworkImage from '../../assets/image/networkSignal.png';
import PlanDetailLoader from './PlanDetailLoader';
import CarouselComponent from '../utils/CarouselComponent';
import shareImage from '../../assets/image/share-icons.png'
import { FacebookShareButton, FacebookIcon, EmailIcon, EmailShareButton, WhatsappIcon, WhatsappShareButton, XIcon, TwitterShareButton } from 'react-share'
import PlanDetailsCarousel from '../utils/PlanDetailsCarousel';
const countryList = require('country-list');


function PlanDetails() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { getPlanByReferenceURL, validateToken, resetHomePage } = homeActions;
  const planDetailsResult = useSelector((state) => state.homeReducer.planDetailsResult);
  const tokenError = useSelector((state) => state.homeReducer.tokenError);
  const tokenMessage = useSelector((state) => state.homeReducer.tokenMessage);
  const loading = useSelector((state) => state.homeReducer.loading);
  const [token, setToken] = useState(null);
  const [buyPlanButtonShow, setbuyPlanButtonShow] = useState(false);
  const [popover__content, setpopover__content] = useState('popover__content')
  const [dummyData, setDummyData] = useState([1]);
  const ref = useRef();


  useEffect(() => {
    window.scrollTo(0, 0);
    const url = window.location.href;
    const parts = url.split('/');
    const referenceURl = parts[parts.length - 1];
    dispatch(getPlanByReferenceURL(referenceURl));
    const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;
    setToken(token);
    dispatch(validateToken());
  }, [window.location.href])



  const handleAcceptAgreement = (e, selectId) => {
    const checked = e.target.checked
    if (checked === true) {
      setbuyPlanButtonShow(true);
    } else {
      setbuyPlanButtonShow(false);
    }
  }

  const handleBuyNow = (id) => {
    if (tokenError) {
      history.push('/login')
    }
    setTimeout(() => {
      dispatch(resetHomePage());
    }, 2000)
  }
  const popOverOpen = () => {
    if (popover__content === 'popover__content')
      setpopover__content('popover__content popoverShow')
    else if (popover__content === 'popover__content popoverShow') {
      setpopover__content('popover__content')
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setpopover__content('popover__content')
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref])
  return (
    <>
      <div className='heit-set'>
        <section className="plan-sec-2">
          {loading ? dummyData.map((item, index) => {
            return (
              <div className="container" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='card' id="card-ab-plan">
                  <div className='row justify-content-evenly'>
                    <div className='col-lg-5'>
                      <div key={index} className="card d-flex is-loading" style={{ top: "18px" }} id='inner-card-one' >
                        <div className="image"></div>
                        <div className="content">
                          <h2></h2>
                          <p className="p-2"></p>
                          <p></p>
                          <p className="p-2-one"></p>
                        </div>
                      </div>
                    </div>

                    <div className='col-lg-5'>
                      <div key={index} className="card d-flex is-loading" style={{ top: "18px" }} id='inner-card-one' >
                        {/* <div className="image"></div> */}
                        <div className="content">
                          <h2></h2>
                          <p className="p-2"></p>
                          <p className='inner-side-one'></p>
                          <p className='inner-side-one'></p>
                          <p className='inner-side-one'></p>
                          <p className='inner-side-one'></p>
                          <p className='inner-side-one'></p>
                          <p className='inner-side-one'></p>
                          <p className='inner-side-one'></p>
                          {/* <p className="p-2-one"></p> */}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-6'>
                          <div key={index} className="card supported d-flex is-loading" style={{ top: "18px" }} id='inner-card-one' >
                            {/* <div className="image"></div> */}
                            <div className="content">
                              <h2 className='h2-tilt'></h2>
                              <div className='ab-card is-loading d-flex align-items-center justify-content-between'>
                                <p className='inner-side-one-w'></p>

                              </div>

                              {/* <p className="p-2-one"></p> */}
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-6'>
                          <div key={index} className="card supported d-flex is-loading" style={{ top: "18px" }} id='inner-card-one' >
                            {/* <div className="image"></div> */}
                            <div className="content">
                              <h2 className='h2-tilt'></h2>
                              <div className='ab-card is-loading d-flex align-items-center justify-content-between'>
                                <p className='inner-side-one'></p>
                                <p className='inner-side-one'></p>
                              </div>
                              <div className='ab-card is-loading d-flex align-items-center justify-content-between'>
                                <p className='inner-side-one'></p>
                                <p className='inner-side-one'></p>
                              </div>
                              <div className='ab-card is-loading d-flex align-items-center justify-content-between'>
                                <p className='inner-side-one'></p>
                                <p className='inner-side-one'></p>
                              </div>



                              {/* <p className="p-2-one"></p> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row my-2'>
                        <div className='col-lg-12'>
                          <div key={index} className="card supported d-flex is-loading" style={{ top: "18px" }} id='inner-card-one' >
                            {/* <div className="image"></div> */}
                            <div className="content">
                              <h2 className='h2-tilt'></h2>
                              <div className="image"></div>

                              {/* <p className="p-2-one"></p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>


              </div>
            )
          }) : ''}
          {planDetailsResult && planDetailsResult.length > 0 && planDetailsResult.map((select, index) => {
            const networkName = select.NetworkName && select.NetworkName != null && select.NetworkName.split(",");
            const imagesvg = `${process.env.REACT_APP_BASEURL}/planimage/${select.ImageUrl}`;
            const title = `${select.PlanName} plan, Priced at $ ${select.PlanPrice}.
          Click here for more details: `;
            const shareUrl = `${process.env.REACT_APP_WEBSITE_BASE_URL}/plan-details/${select.ReferenceURL}`
            return (
              <div className="container" key={index}>
                <div className='contain-box py-4'>
                  <div className="row d-flex justify-content-center">
                    <div className="col-lg-5 col-md-12 col-sm-12 pro-sticky">
                      <div className="cards">
                        <center>
                          {/* <img src={imagesvg} className="card-img-top img-fluid" alt="..." /> */}
                          <Image preview={false} fallback={noImageFound} src={imagesvg} className='card-img-top' />
                        </center>
                        <div className="card-body">
                          <div className="card-text-5">


                            {/* row */}
                            <div className={'row'}>

                              <div className='col-lg-12 col-md-12'>
                                <div className='w-100 mb-2'>
                                  <span className="day-5gb"> <strong> {select.PlanName} </strong></span>
                                </div>
                                <div className='w-100 plan-data-new-design py-2 '>
                                  <span>{select.PlanDataValue + ' ' + select.PlanDaysValue}</span>
                                  <span className="dolor">${select.PlanPrice}</span>
                                </div>
                                <div className=" i-agree-main mt-1">
                                  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={(e) => handleAcceptAgreement(e, select.ID)} />
                                  <label className="form-check-label" htmlFor="flexCheckDefault">
                                    I agree with the <strong><Link to="/terms-condition">terms & condition</Link></strong>  and <strong><Link to="/privacy-policy">privacy policy</Link></strong>.
                                  </label>
                                </div>
                                <div className="col btn-product-box my-4 ">
                                  {buyPlanButtonShow === true ? <Link className="btn-more-details" to={token && token !== null && token !== '' && token !== undefined && tokenMessage === 'valid token' ? `/check-out/${select.ReferenceURL}` : "/login"}><span onClick={(e) => handleBuyNow(select.ID)}>Buy Now</span></Link> :
                                    <Link className="btn-more-details-disable" to="#"><span>Buy Now</span></Link>}
                                </div>
                              </div>
                            </div>
                            {/* row */}



                          </div>




                        </div>
                      </div>
                      {/* card-details-row  */}

                      {/* card-details-row  */}

                    </div>
                    <div className="col-lg-5 col-md-12 col-sm-12">

                      <div className="details-card-1">

                        <p className="details-para-1">This data package works on UNLOCKED  <strong style={{ "color": "#f8921f" }}> eSIM compatible devices.</strong> It provides  <strong style={{ "color": "#727272" }}>{select.PlanDataValue}</strong>  of data valid over  <strong style={{ "color": "#727272" }}> {select.PlanDaysValue} </strong></p>
                        <div class="popover__wrapper" ref={ref} >
                          <p onClick={popOverOpen} style={{ cursor: "pointer", color: "black", fontSize: "14px" }} data-bs-container="body" data-bs-toggle="popover" data-bs-placement="bottom" data-bs-trigger="focus" data-bs-content="Bottom popover" ><img src={shareImage} width={20} alt="share" /></p>
                          <div class={popover__content}>
                            <FacebookShareButton
                              url={shareUrl}
                              className="Demo__some-network__share-button"
                            >
                              <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <TwitterShareButton
                              url={shareUrl}
                              title={title}
                              className="Demo__some-network__share-button"
                            >
                              <XIcon size={32} round />
                            </TwitterShareButton>
                            <WhatsappShareButton
                              url={shareUrl}
                              title={title}
                              className="Demo__some-network__share-button"
                            >
                              <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                            <EmailShareButton
                              url={shareUrl}
                              subject={title}
                              body="body"
                              className="Demo__some-network__share-button"
                            >
                              <EmailIcon size={32} round />
                            </EmailShareButton>
                          </div>
                        </div>

                        {select.AvailableForFlag === 19 ?
                          <h5 className="details-para-3 mb-3" >COVERAGE: {select.CountryISO2 && select.CountryISO2.length > 0 && `${select.CountryISO2.length} Countries`}</h5>
                          :
                          <h5 className="details-para-3 mb-3" >COVERAGE: {select.AvailableForIDValue}</h5>

                        }

                        <div dangerouslySetInnerHTML={{ __html: select.PlanDesc }} className='cov-list' />



                        <div className='row mb-2'>
                          <div className='col-lg-6 col-md-6'>

                            <h5 className="details-para-3">Supported Countries: {select.AvailableForFlag === 20 ? "Global" : ""}</h5>
                            {select.AvailableForFlag === 20 ? "" :
                              <div className="scrol-b-details">
                                <ul className="srcol-detail-ul">
                                  {select.CountryISO2.map((res, index) => {
                                    const countryName = countryList.getName(res);
                                    return (
                                      <li key={index} className="scroll-detail-li" data-bs-toggle="tooltip" data-bs-placement="top" title={countryName}><CountryFlag countryCode={res} svg style={{ width: '40px', height: '40px', borderRadius: "9px", }} /></li>
                                    )
                                  })
                                  }
                                </ul>
                              </div>
                            }
                          </div>
                          <div className='col-lg-6 col-md-6 col-sm-12'>
                            {/* card-detail-2  */}
                            <h5 className="details-para-3" id='details-ps-3'>Available Top-UP Packages</h5>
                            <div class="plan" id='plan-car-div'>
                              <div class="inner-2">

                                <span class="pricing-2">
                                  <span>
                                    {select.PlanDataValue + ' ' + select.PlanDaysValue}
                                  </span>
                                </span>
                                <ul class="features">
                                  <li>
                                    <div className='icon-set'>
                                      <span class="icon-2">

                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"><path stroke="#d14949cc" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="1.5" d="M9.01 20.5l-5.02-5.01M9.01 3.5v17M14.99 3.5l5.02 5.01M14.99 20.5v-17"></path></svg>
                                      </span>
                                      <span className='feature-span-2'><strong>Data</strong></span>
                                    </div>
                                    <div><span className='feature-span-2'><strong>{select.PlanDataValue}</strong></span></div>
                                  </li>
                                  <li>
                                    <div className='icon-set'>
                                      <span class="icon-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"><path d="m8.38 12 2.41 2.42 4.83-4.84" stroke="#d14949cc" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M10.75 2.45c.69-.59 1.82-.59 2.52 0l1.58 1.36c.3.26.86.47 1.26.47h1.7c1.06 0 1.93.87 1.93 1.93v1.7c0 .39.21.96.47 1.26l1.36 1.58c.59.69.59 1.82 0 2.52l-1.36 1.58c-.26.3-.47.86-.47 1.26v1.7c0 1.06-.87 1.93-1.93 1.93h-1.7c-.39 0-.96.21-1.26.47l-1.58 1.36c-.69.59-1.82.59-2.52 0l-1.58-1.36c-.3-.26-.86-.47-1.26-.47H6.18c-1.06 0-1.93-.87-1.93-1.93V16.1c0-.39-.21-.95-.46-1.25l-1.35-1.59c-.58-.69-.58-1.81 0-2.5l1.35-1.59c.25-.3.46-.86.46-1.25V6.2c0-1.06.87-1.93 1.93-1.93h1.73c.39 0 .96-.21 1.26-.47l1.58-1.35Z" stroke="#d14949cc" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                      </span>
                                      <span className='feature-span-2'><strong>Validity</strong></span>
                                    </div >
                                    <div><span className='feature-span-2'><strong>{select.PlanDaysValue}</strong></span></div>
                                  </li>
                                  <li>
                                    <div className='icon-set'>
                                      <span class="icon-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"><path d="M8.672 14.33c0 1.29.99 2.33 2.22 2.33h2.51c1.07 0 1.94-.91 1.94-2.03 0-1.22-.53-1.65-1.32-1.93l-4.03-1.4c-.79-.28-1.32-.71-1.32-1.93 0-1.12.87-2.03 1.94-2.03h2.51c1.23 0 2.22 1.04 2.22 2.33M12 6v12" stroke="#d14949cc" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z" stroke="#d14949cc" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>

                                      </span>
                                      <span className='feature-span'><strong>Price</strong></span>
                                    </div>
                                    <div className='feature-span'><span><strong>${select.PlanPrice}</strong></span></div>
                                  </li>
                                </ul>

                                {/* <div class="action">
                          <a class="button" href="#">
                            Choose plan
                          </a>
                        </div> */}
                              </div>
                            </div>
                            {/* card-detail-2  */}
                          </div>


                        </div>
                        <div className='row mb-2'>
                          <div className='col-lg-12 col-md-12 col-sm-12 my-3'>
                            <h5 className='details-para-3'>Additional Information</h5>

                            {/* new card  */}
                            <div class="plan">
                              <div class="inner">
                                <span class="title">Network</span>
                                <span class="pricing">
                                  <span data-bs-toggle="modal" data-bs-target={`#networkModel`}>
                                    Check Network
                                  </span>
                                </span>
                                <ul class="features">
                                  <li>
                                    <div>
                                      <span class="icon">
                                        <svg height="15" width="15" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M0 0h24v24H0z" fill="none"></path>
                                          <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                                        </svg>
                                      </span>
                                      <span className='feature-span'>eKYC (IDENTITY VERIFICATION)</span>
                                    </div>
                                    <div><span className='feature-span'>Not Required</span></div>
                                  </li>
                                  <li>
                                    <div>
                                      <span class="icon">
                                        <svg height="15" width="15" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M0 0h24v24H0z" fill="none"></path>
                                          <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                                        </svg>
                                      </span>
                                      <span className='feature-span'>Plan Type</span>
                                    </div>
                                    <div><span className='feature-span'>Data only</span></div>
                                  </li>
                                  <li>
                                    <div>
                                      <span class="icon">
                                        <svg height="15" width="15" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M0 0h24v24H0z" fill="none"></path>
                                          <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                                        </svg>
                                      </span>
                                      <span className='feature-span'>Top-UP OPTION</span>
                                    </div>
                                    <div className='feature-span'><span>Available</span></div>
                                  </li>

                                  <li>
                                    <div>
                                      <span class="icon">
                                        <svg height="15" width="15" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                          <path d="M0 0h24v24H0z" fill="none"></path>
                                          <path fill="currentColor" d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                                        </svg>
                                      </span>
                                      <span className='feature-span'>ACTIVATION POLICY</span>
                                    </div>

                                  </li>

                                </ul>
                                <p class="info">The validity period starts when the eSim connects to any supported networks.</p>
                                {/* <div class="action">
                          <a class="button" href="#">
                            Choose plan
                          </a>
                        </div> */}
                              </div>
                            </div>
                            {/* new card  */}


                          </div>
                        </div>

                        {select.SuggestedPlans && <PlanDetailsCarousel heading={'Recomended Plans'} planDataResult={planDetailsResult} loading={loading} planData={select.SuggestedPlans} />}

                        <div className="modal fade" id={`networkModel`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Supported Networks</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div className="modal-body">
                                <div className='scroll-bar-network-model'>
                                  <ul className='ul-network-model'>
                                    {networkName && networkName.length > 0 &&
                                      networkName.map((network, index) => {
                                        return (
                                          <li key={index} className='network-li-flag-name-networkmodel' data-bs-toggle="tooltip" data-bs-placement="top" ><img src={NetworkImage} alt="" style={{ marginRight: "8px" }} width={20} />{network}</li>
                                        )
                                      })}
                                  </ul>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>





                        {/* <div className="details-card">
                      <div id="carouselExampleControlsNoTouching" className="carousel slide" data-bs-touch="false" data-bs-interval="false">
                        <div className="carousel-inner">
                          <div className="carousel-item active">
                            <div className="slider-header">

                              <p className="dta-pack">{select.PlanDataValue}-{select.PlanDaysValue}</p>
                            </div>
                            <div className="plan-type">
                              <span>Data</span>
                              <span>{select.PlanDataValue}</span>
                            </div>
                            <div className="plan-type">
                              <span>Validity</span>
                              <span>{select.PlanDaysValue}</span>
                            </div>

                            <div className="plan-type">
                              <span>Price</span>
                              <span>$ {select.PlanPrice}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                      </div>
                    </div>
                  </div>


                </div>

              </div>
            )
          })}
        </section>
      </div>



      {/* {loading && <PlanDetailLoader />} */}
      {/* <PlanDetailLoader /> */}

    </>
  )
}

export default PlanDetails;