import React,{useEffect} from 'react'

const PrivacyPolicy = () => {
    let currentDate = new Date().toUTCString().slice(5, 16);
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [])
    return (
        <div>
            <div className="container py-5">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className='mb-4'>Privacy Policy</h2>
                        <p>
                            This Privacy Policy ("Policy") describes how Mahatel LLC ("Company," "we," "us," or "our") collects, uses, and protects the personal information of individuals ("User," "you," or "your") who visit and use our website selling eSIM services ("Website"). This Policy applies to the collection and processing of personal data through the Website.
                        </p>
                        <h6><strong>Information We Collect</strong></h6>
                        <p>
                            a. Personal Information: When you use our Website, we may collect personal information, such as your name, email address, phone number, billing address, and other relevant details required for the purchase and activation of eSIM services.
                        </p>
                        <p>
                            b. Usage Data: We may collect non-personal information about your interactions with the Website, including your IP address, browser type, device information, and website usage patterns.
                        </p>
                        <h6><strong>Use of Information</strong></h6>
                        <p>a. We collect and use your personal information to provide you with the requested eSIM services, process transactions, and deliver customer support.</p>
                        <p>b. We may use your information to personalize your experience, improve our Website, and send you relevant notifications and updates about your eSIM services.</p>
                        <p>c. We may use non-personal information for analytical purposes, such as tracking user trends, monitoring the effectiveness of our marketing campaigns, and enhancing our services.</p>
                        <h6><strong>Data Security</strong></h6>
                        <p>a. We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.</p>
                        <p>b. While we strive to protect your data, no method of transmission over the internet or electronic storage is completely secure. Therefore, we cannot guarantee absolute security of your personal information.</p>
                        <h6><strong>Third-Party Service Providers</strong></h6>
                        <p>a. We may engage trusted third-party service providers to assist us in delivering our services, such as payment processors and customer support platforms. These providers have access to personal information necessary to perform their functions but are prohibited from using it for other purposes.</p>
                        <p>b. We may also share non-personal information with third-party analytics and marketing providers to improve our services and offer personalized content and advertisements.</p>
                        <h6><strong>Legal Basis for Processing</strong></h6>
                        <p>a. We process your personal information based on your consent and to fulfill our contractual obligations to provide you with eSIM services.</p>
                        <p>b. We may also process personal information to comply with legal obligations or protect our legitimate interests, such as preventing fraud or ensuring network and information security.</p>
                        <h6><strong>Data Retention</strong></h6>
                        <p>a. We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required by law.</p>
                        <p>b. We will securely delete or anonymize your personal information once it is no longer needed for the purposes stated in this Policy.</p>
                        <h6><strong>Your Rights</strong></h6>
                        <p>a. You have the right to access, rectify, or delete your personal information. You may also have the right to restrict or object to certain processing activities.</p>
                        <p>b. To exercise your rights or request further information about our data practices, please contact us using the contact details provided in this Policy.</p>
                        <h6><strong>Children's Privacy</strong></h6>
                        <p>Our Website is not intended for use by individuals under the age of 16. We do not knowingly collect personal information from children. If we become aware that we have collected personal information from a child without parental consent, we will take steps to remove that information from our systems.</p>
                        <h6><strong>Changes to this Privacy Policy</strong></h6>
                        <p>a. We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting on the Website.</p>
                        <p>b. We encourage you to review this Policy periodically for any updates or changes.</p>
                        <h6><strong>Contact Us</strong></h6>
                        <p>If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at [contact information].<br />
                            This Privacy Policy was last updated on {currentDate}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy
