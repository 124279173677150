import React, { useState, useEffect } from "react";
import noImageFound from '../../assets/image/no-image-found.png';
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import choose from "../../assets/image/choose.png";
import connect from "../../assets/image/connect.png";
import purchase from "../../assets/image/purchase.png";
import scan from "../../assets/image/scan.png";
import CountryFlag from "react-country-flag";
import { Input, Form, Button, Image } from "antd";
import homeActions from "../../redux/home/actions";
import testimonialsActions from "../../redux/testimonials/actions";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import Loading from "../Loader/Loading";
import Carousel from "react-grid-carousel";
import noPlanFoundImg from "../../assets/image/no-data-found.png";
import Error from "../Error/Error";
import CarouselComponent from "../utils/CarouselComponent";
import HomeSlider from "../utils/HomeSlider";
const countryList = require("country-list");

function Home() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    getSearchPlan,
    getPlanData,
    checkCompatibleStatus,
    resetHomePage,
    sendSelectedPlan,
  } = homeActions;
  const { getTestimonialsData } = testimonialsActions;
  const { searchRegionResult, searchCountryResult, planDataResult } =
    useSelector((state) => state.homeReducer);
  const successMessage = useSelector(
    (state) => state.homeReducer.compatibleMesssage
  );
  const imeiStatus = useSelector(
    (state) => state.homeReducer.imeiStatus
  );
  const internalServerError = useSelector((state) => state.homeReducer.error);
  const errorMessage = useSelector(
    (state) => state.homeReducer.compatibleError
  );
  const compatibleMesssage = useSelector(
    (state) => state.homeReducer.compatibleMesssage
  );
  const compatibleError = useSelector(
    (state) => state.homeReducer.compatibleError
  );
  const imeiNumber = useSelector((state) => state.homeReducer.imeiNumber);
  const searchLoading = useSelector((state) => state.homeReducer.searchLoading);
  const testimonialsResult = useSelector((state) => state.testimonialsReducer.testimonialsResult);
  const loading = useSelector((state) => state.homeReducer.loading);
  const buttonLoading = useSelector((state) => state.homeReducer.buttonLoading);
  const [showSearchDropdown, setShowSearchDropdown] = useState(false);
  const [searchIconsShow, setSearchIconsShow] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [emiNumber, setEmiNumber] = useState("");
  const [message, setMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [regionWiseData, setRegionWiseData] = useState([]);
  const [form] = Form.useForm();
  const [countryWiseData, setCountryWiseData] = useState([]);
  const [dummyData, setDummyData] = useState([1, 2, 3, 4, 5]);
  const currentDateTime = new Date();
  const millisecondsSinceEpoch = currentDateTime.getTime();
  useEffect(() => {
    window.scrollTo(0, 0);
    let filterData = {
      availableForFlag: "",
      availableForID: "",
      limit: "60",
      token:
        localStorage.getItem("token") !== null
          ? localStorage.getItem("token")
          : null,
    };
    dispatch(getPlanData(filterData));
    dispatch(getTestimonialsData());
  }, []);

  useEffect(() => {
    const filterRegionData = planDataResult.filter(
      (item) =>
        item.AvailableForFlag === 19 && item.DateLatestAvailable >= millisecondsSinceEpoch
    );
    const filterCountryData = planDataResult.filter(
      (item) =>
        item.AvailableForFlag === 18 && item.DateLatestAvailable >= millisecondsSinceEpoch
    );

    setRegionWiseData(filterRegionData);
    setCountryWiseData(filterCountryData);
  }, [planDataResult]);

  const handleSearchPlan = (e) => {
    const fvalue = e.target.value;
    setSearchValue(fvalue);
    if (fvalue.length > 0) {
      setSearchIconsShow(false);
    } else {
      setSearchIconsShow(true);
    }
    if (fvalue.length >= 3) {
      let filter = {
        search: fvalue,
      };
      setShowSearchDropdown(true);
      dispatch(getSearchPlan(filter));
    } else {
      setShowSearchDropdown(false);
      let filterData = {
        availableForFlag: "",
        availableForID: "",
        limit: "40",
        token:
          localStorage.getItem("token") !== null
            ? localStorage.getItem("token")
            : null,
      };
      dispatch(getPlanData(filterData));
    }
  };

  const handleSubmitEmi = () => {
    dispatch(checkCompatibleStatus(emiNumber));
  };

  function sumDig(n) {
    let a = 0;
    while (n > 0) {
      a = a + (n % 10);
      n = parseInt(n / 10, 10);
    }
    return a;
  }

  function isValidIMEI(n) {
    // Converting the number into
    // String for finding length
    let s = n.toString();
    let len = s.length;

    if (len !== 15) return false;

    let sum = 0;
    for (let i = len; i >= 1; i--) {
      let d = n % 10;

      // Doubling every alternate digit
      if (i % 2 === 0) d = 2 * d;

      // Finding sum of the digits
      sum += sumDig(d);
      n = parseInt(n / 10, 10);
    }

    return sum % 10 === 0;
  }


  const handleChange = (e) => {
    const emiNumber = parseInt(e.target.value, 10);
    if (isValidIMEI(emiNumber)) {
      setMessage("Valid IMEI Code");
      setIsButtonDisabled(false);
    } else {
      setMessage("Invalid IMEI Code");
      setIsButtonDisabled(true);
    }
    const fname = e.target.name;
    const fvalue = e.target.value;
    setEmiNumber((prevState) => ({
      ...prevState,
      [fname]: fvalue,
    }));
  };

  const clearSearch = () => {
    setSearchValue("");
    setSearchIconsShow(true);
    setShowSearchDropdown(false);
    let filterData = {
      availableForFlag: "",
      availableForID: "",
      limit: "40",
      token:
        localStorage.getItem("token") !== null
          ? localStorage.getItem("token")
          : null,
    };
    dispatch(getPlanData(filterData));
  };

  const NavigateToPlanDetailPage = (referenceURL) => {
    history.push(`/plan-details/${referenceURL}`);
  }

  const handleClose = () => {
    dispatch(resetHomePage());
  };

  const handleSearchPlanByFlag = (availableFlag, flagId) => {
    let filterData = {
      availableForFlag: availableFlag,
      availableForID: flagId,
      limit: "",
      token:
        localStorage.getItem("token") !== null
          ? localStorage.getItem("token")
          : null,
    };
    dispatch(getPlanData(filterData));
    setShowSearchDropdown(false);
  };

  const errorEmei = (text) => {
    Swal.fire({
      position: "center",
      icon: "error",
      title: `IMEI: ${imeiNumber}`,
      text: `${text}`,
      showConfirmButton: true,
      customClass: {
        popup: "custom-popup-class",
        title: "custom-title-class",
        confirmButton: "custom-button-class",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (compatibleError !== null) {
          form.resetFields();
          setMessage("");
          setEmiNumber("");
          setIsButtonDisabled(true);
        }
        handleClose();
      }
    });
  };

  const successEmei = (text) => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: `IMEI: ${imeiNumber}`,
      text: `${text}`,
      showConfirmButton: true,
      customClass: {
        popup: "custom-popup-class",
        title: "custom-title-class",
        confirmButton: "custom-button-class",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (compatibleMesssage !== null) {
          form.resetFields();
          setMessage("");
          setEmiNumber("");
          setIsButtonDisabled(true);
        }
        handleClose();
      }
    });
  };

  const tabletConfig = {
    cols: 2,
    rows: 1,
    gap: 10,
    loop: true,
    autoplay: 1000,
  };

  const deskConfig = {
    cols: 4,
    rows: 1,
    gap: 10,
    loop: true,
    autoplay: 1000,
  };

  const getCarouselConfig = () => {
    if (window.innerWidth <= 1200) {
      return tabletConfig; // For tablets
    } else {
      // You can add additional configurations for larger screens if needed
      return deskConfig; // Default to tablet configuration
    }
  };

  const carouselConfig = getCarouselConfig();

  return (
    <>
      {internalServerError ? (
        <Error />
      ) : (
        <>

          {compatibleMesssage !== null && compatibleMesssage !== undefined && imeiStatus == 'yes' && successEmei(compatibleMesssage)}
          {compatibleMesssage !== null && compatibleMesssage !== undefined && imeiStatus == 'NA' && errorEmei(compatibleMesssage)}

          {/* slider  */}
          <HomeSlider />
          {/* slider  */}
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="col inner-div">
                  <h3 className="heading-1">
                    Stay connected, wherever you travel, at affordable rates
                  </h3>
                  <p className="para-1">
                    Our eSIMs are trusted by over 1000,000 people worldwide.
                  </p>
                  <div className="search-box">
                    <Input
                      placeholder="Search data packs for 200+ countries and region"
                      autoComplete="off"
                      name="search"
                      className="search-input"
                      id="search-input"
                      value={searchValue}
                      onChange={(e) => handleSearchPlan(e)}
                    />
                    {searchIconsShow === true ? (
                      <button className="search-btn">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                      </button>
                    ) : (
                      <button className="search-btn" onClick={clearSearch}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="35"
                          height="35"
                          fill="currentColor"
                          className="bi bi-x"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                      </button>
                    )}
                    {showSearchDropdown && (
                      <div className="autocomplete-suggestions autocomplete-responsive">
                        {searchCountryResult &&
                          searchCountryResult.length > 0 && (
                            <div className="countries-search-segment segment-country">
                              <p>Country</p>
                            </div>
                          )}
                        {searchCountryResult &&
                          searchCountryResult.length > 0 &&
                          searchCountryResult.map((data, index) => {
                            return (
                              <div
                                className="autocomplete-suggestion"
                                data-index="147"
                                key={index}
                                onClick={() =>
                                  handleSearchPlanByFlag("18", data.ID)
                                }
                              >
                                <CountryFlag
                                  countryCode={data.ISO2}
                                  svg
                                  style={{ width: "20px", height: "20px" }}
                                />
                                &nbsp;&nbsp;{data.CountryName}
                              </div>
                            );
                          })}
                        {searchRegionResult &&
                          searchRegionResult.length > 0 && (
                            <div className="countries-search-segment segment-region">
                              <p>Region</p>
                            </div>
                          )}
                        {searchRegionResult &&
                          searchRegionResult.length > 0 &&
                          searchRegionResult.map((data, index) => {
                            return (
                              <div
                                className="autocomplete-suggestion"
                                data-index="147"
                                key={index}
                                onClick={() =>
                                  handleSearchPlanByFlag("19", data.ID)
                                }
                              >
                                {data.Name}
                              </div>
                            );
                          })}
                        <div className="countries-search-segment segment-global">
                          <p>Global</p>
                        </div>
                        <div
                          className="autocomplete-suggestion"
                          data-index="147"
                          onClick={() => handleSearchPlanByFlag("20", "")}
                        >
                          Discover Global
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* section 1 */}

          {/* section 2 for Desktop view */}
          <section className="sec-2 p-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="col traveling mb-5">
                    <h5 className="traveling-title">
                      Where are you traveling next? Pick our best selling plans.
                    </h5>
                    <p className="traveling-para">
                      Stay connected to the internet on your trip. Buy a travel
                      esim and forget about paying roaming fees.
                    </p>
                  </div>
                  <div className="row">
                    <div className="w-50">
                      <h5 className="reg-heading">Country Plans</h5>
                    </div>
                    <div className="w-50 text-end">
                      {planDataResult && planDataResult.length > 0 ? (
                        <Link className="more_plan_btn" to="/shop">
                          {" "}
                          <span>More plans</span>
                        </Link>
                      ) : null}
                    </div>
                  </div>
                  <Carousel cols={carouselConfig.cols} rows={carouselConfig.rows} gap={carouselConfig.gap} loop={carouselConfig.loop}>
                    {countryWiseData && countryWiseData.length > 0 ?
                      countryWiseData.map((plan, index) => {
                        const imagesvg = `${process.env.REACT_APP_BASEURL}/planimage/${plan.ImageUrl}`;
                        return (
                          <Carousel.Item key={index}>
                            <div className="col">
                              <div className="card" >
                                {plan.FeaturedPlan === 1 && <h1 className="ribbon" style={{ backgroundColor: "#B70404" }}>Featured</h1>}
                                {plan.BestSeller === 1 && <h1 className="ribbon">Best Seller</h1>}
                                <div className="image-container image " onClick={() => NavigateToPlanDetailPage(plan.ReferenceURL)}>

                                  <Image preview={false} fallback={noImageFound} src={imagesvg} />
                                </div>
                                <div className="card-body card-body-1 ">
                                  <ul className="ul-margin content">
                                    <li className="bahamas" onClick={() => NavigateToPlanDetailPage(plan.ReferenceURL)}>{plan.PlanName}</li>
                                    <li className="nrth-amrica">
                                      {plan.PlanDataValue +
                                        " " +
                                        plan.PlanDaysValue}
                                    </li>
                                  </ul>
                                  <div className="card-text-2">
                                    <span className="day-5gb">COVERAGE</span>
                                    <span className="dolor-coverage-Country-homepage">
                                      {plan.AvailableForIDValue}
                                    </span>
                                  </div>
                                  <div className="price-text">
                                    <span className="dolor">
                                      <sup>$</sup>
                                      {plan.PlanPrice}
                                    </span>
                                    <Link
                                      to={`/plan-details/${plan.ReferenceURL}`}
                                      className="shop"
                                    >
                                      <i className="fas fa-shopping-cart"></i>
                                    </Link>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </Carousel.Item>
                        );
                      })
                      : loading || searchLoading ?
                        dummyData.map((item, index) => {
                          return (
                            <Carousel.Item key={index}>
                              <div className="card is-loading" id="card-ab">
                                <div className="image"></div>
                                <div className="content">
                                  <h2></h2>
                                  <p className="p-2"></p>
                                  <p></p>
                                  <p className="p-2"></p>

                                </div>
                              </div>
                            </Carousel.Item>
                          )
                        }) : (
                          <Carousel.Item>
                            <div className="col-lg-12 col-md-12 col-sm-12 pt-4 d-flex align-items-center justify-content-center">
                              <div className="noPlanFoundImg">
                                <img src={noPlanFoundImg} alt="" />
                              </div>
                            </div>
                          </Carousel.Item>
                        )
                    }
                  </Carousel>
                </div>
              </div>
            </div>
          </section>
          {/* Model for Regional Plan */}
          {regionWiseData &&
            regionWiseData.length > 0 &&
            regionWiseData.map((plan, index) => (
              <div
                key={index}
                className="modal fade"
                id={`staticModel${plan.ID}`}
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Supported Countries
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="scroll-bar-country-model">
                        <ul className="ul-country-model">
                          {plan.CountryISO2 &&
                            plan.CountryISO2.length > 0 &&
                            plan.CountryISO2.map((country, index) => {
                              const countryName = countryList.getName(country);
                              return (
                                <li
                                  key={index}
                                  className="country-li-flag-name-countymodel"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title={countryName}
                                >
                                  {plan.AvailableForFlag === 20 ? (
                                    ""
                                  ) : (
                                    <span className="bd-flag">
                                      <CountryFlag
                                        countryCode={country}
                                        svg
                                        style={{
                                          width: "39px",
                                          height: "41px",
                                          borderRadius: "9px",
                                          margin: "-6px 0 0 -8px",
                                        }}
                                      />
                                    </span>
                                  )}
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {/* section 3 Regional Plan */}


          <CarouselComponent heading={'Regional Plans'} planDataResult={planDataResult} loading={loading} planData={regionWiseData} searchLoading={searchLoading} />

          {/* section 4 */}
          <section className="sec-4">
            <div className="container">
              <h3 className="heading-2">
                Here's What Our Customers Have to Say
              </h3>
              <div className="row">
                <div className="slider">
                  <Carousel cols={3} rows={1} gap={1} breakpoint="767px" loop>
                    {testimonialsResult && testimonialsResult.length > 0 ? testimonialsResult.map((data, index) => {
                      return (
                        <Carousel.Item key={index}>
                          <div className="slider-card">
                            <div className="col body-carousel">
                              <h4 className="heading-carousel-emap">
                                {data.Person}, {data.CountryName}
                              </h4>
                              <p className="para-carousel-emap">
                                {data.Message}
                              </p>
                            </div>
                          </div>
                        </Carousel.Item>
                      )
                    }) : (
                      <Carousel.Item>
                        <div className="slider-card">
                          <div className="col body-carousel">
                            <h4 className="heading-carousel-emap">
                            </h4>
                            <p className="para-carousel-emap">
                              No Data Found
                            </p>
                          </div>
                        </div>
                      </Carousel.Item>
                    )
                    }
                  </Carousel>
                </div>
              </div>
            </div>
          </section>

          {/* section 5 */}
          <section className="sec-6">
            <div className="container">
              <div className="row">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <h2 className="heading-3">Check your phone compatible</h2>
                  <p className="most-text">
                    Most of the newest models of IOS And Android Devices support
                    eSIM But enter your device IMEI to check the compatibility
                  </p>
                  <Form
                    id="camparitbaleForm"
                    form={form}
                    className="row-col"
                    size="large"
                    onFinish={handleSubmitEmi}
                  >
                    <Form.Item className="usernam" name="imeiNumber">
                      <div className="d-flex">
                        <Input
                          placeholder="Enter Your Phone IMEI No."
                          className="antdInputPassword"
                          rules={[
                            {
                              required: true,
                              message: "Please input IMEI No!",
                            },
                          ]}
                          name="imeiNumber"
                          onChange={handleChange}
                        />
                        <Button
                          type="primary"
                          loading={buttonLoading}
                          style={{
                            color: "#fff",
                            backgroundColor: "#000",
                            fontWeight: "600",
                            marginLeft: "20px",
                            padding: "0 30px",
                            height: "45px",
                          }}
                          disabled={isButtonDisabled}
                          htmlType="submit"
                        >
                          Submit
                        </Button>
                      </div>
                    </Form.Item>
                    {message && (
                      <h6
                        style={{
                          color:
                            message === "Valid IMEI Code" ? "green" : "red",
                          marginTop: "-17px",
                          padding: "4px 6px",
                        }}
                      >
                        {message}
                      </h6>
                    )}
                  </Form>
                  <p className="most-text">
                    Dial '*#06#' to get the IMEI or go to the setting then about
                    to find the IMEI.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* section 6 */}
          <section className="py-5">
            <div className="container">
              <div className="row">
                <h2 className="how-work mb-5">How does our eSIM work?</h2>
                <div className="col-md-3">
                  <div className="work-box">
                    <img src={choose} className="img-fluid" alt="..." />
                    <h5>Choose</h5>
                    <p>
                      Choose your destination & <br />
                      package
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="work-box">
                    <img src={purchase} className="img-fluid" alt="..." />
                    <h5>Purchase</h5>
                    <p>Pay for your package and complete the purchase</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="work-box">
                    <img src={scan} className="img-fluid" alt="..." />
                    <h5>Scan</h5>
                    <p>
                      Scan QR code and install <br />
                      your eSIM
                    </p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="work-box">
                    <img src={connect} className="img-fluid" alt="..." />
                    <h5>Connect</h5>
                    <p>
                      Connect to the internet and
                      <br /> worry free
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}

export default Home;
