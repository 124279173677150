import { useLocation } from 'react-router-dom';

const PathProvider = () => {
  // const location = useLocation();
  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");
  // return location.pathname;
  if (pathname !== 'login') {
    localStorage.setItem('redirectionPathName', pathname)
  }
};

export { PathProvider };