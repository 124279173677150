const actions = {
    GET_REGION: 'GET_REGION',
    GET_REGION_SUCCESS: 'GET_REGION_SUCCESS',
    GET_REGION_FAILED: 'GET_REGION_FAILED',
    getRegion: () => ({
        type: actions.GET_REGION,
    }),
    getRegionSuccess: (regionResult) => ({
        type: actions.GET_REGION_SUCCESS,
        regionResult
    }),
    getRegionFailed: (regionError) => ({
        type: actions.GET_REGION_FAILED,
        regionError
    }),
}
export default actions;