import actions from './actions';
const initState = { message: null, error: null, loading: false };
export default function authReducer(state = initState, action) {
    switch (action.type) {
        case actions.SIGNUP_REQUEST:
            return {
                ...state,
                message: null,
                error: null,
                loading: true
            };
        case actions.SIGNUP_SUCCESS:
            return {
                ...state,
                error: null,
                message: action.message,
                loading: false
            };
        case actions.SIGNUP_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
                message: null,
            };
        case actions.LOGIN_REQUEST:
            return {
                ...state,
                message: null,
                error: null,
                loading: true
            };
        case actions.LOGIN_SUCCESS:
            return {
                ...state,
                error: null,
                message: null,
                token: action.token,
                loading: false
            };
        case actions.LOGIN_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false,
                message: null,
            };
        case actions.LOGOUT_REQUEST:
            return {
                ...state,
                message: null,
                error: null,
                loading: true
            };
        case actions.LOGOUT_SUCCESS:
            return {
                ...state,
                error: null,
                message: null,
                loading: false
            };
        case actions.LOGOUT_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false,
                message: null,
            };
        case actions.VERIFY_EMAIL_ON_FORGET_PASSOWRD:
            return {
                ...state,
                error: null,
                loading: true,
                message: null,
            };
        case actions.VERIFY_EMAIL_ON_FORGET_PASSOWRD_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                message: action.message,
            };
        case actions.VERIFY_EMAIL_ON_FORGET_PASSWORD_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false,
                message: null,
            };
        case actions.RESET_FORGET_PASSWORD:
            return {
                ...state,
                error: null,
                loading: true,
                message: null,
            };
        case actions.RESET_FORGET_PASSWORD_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                message: action.message,
            };
        case actions.RESET_FORGET_PASSWORD_FAILED:
            return {
                ...state,
                error: action.error,
                loading: false,
                message: null,
            };
            case actions.RESET_AUTH_ERROR:
            return {
                ...state,
                error: null,
                loading: false,
                message: null,
            };
        default:
            return state;
    }
}