import actions from "./actions";

const initState = {
    regionResult: [],
    loading: false,
    regionError: null,
    regionMessage: null,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_REGION:
            return {
                ...state,
                loading: true,
                regionError: null,
                regionMessage: null, 
            };
        case actions.GET_REGION_SUCCESS:
            return {
                ...state,
                loading: false,
                regionError: null,
                regionResult: action.regionResult,
                regionMessage: null,
            };
        case actions.GET_REGION_FAILED:
            return {
                ...state,
                loading: false,
                regionError: action.regionError,
                regionMessage: null,
            };
        default:
            return state;
    }
}