import React,{useEffect} from 'react'

const TermsCondition = () => {
  let currentDate = new Date().toUTCString().slice(5, 16);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  return (
    <div>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-12">
          <h2 className='mb-4'>Terms & Conditions</h2>
          <p>These Terms and Conditions ("Agreement") govern your use of the website that sells eSIM services online ("Website"), operated by Mahatel LLC ("Company"). By accessing and using the Website, you agree to comply with these Terms and Conditions. If you do not agree with any part of these Terms and Conditions, please refrain from using the Website.</p>
          <h6><strong>Services Offered</strong></h6>
          <p>a. The Website provides access to eSIM services, including the purchase, activation, and management of eSIM profiles for compatible devices.</p>
          <p>b. The availability of specific eSIM services and features may vary depending on your location and the services offered by mobile network operator.</p>
          <h6><strong>Account Registration and Use</strong></h6>
          <p>a. To access and use the eSIM services on the Website, you may need to create an account.</p>
          <p>b. You are responsible for providing accurate and complete information during the registration process. You agree to keep your account information up to date and secure.</p>
          <p>c. You are solely responsible for maintaining the confidentiality of your account login credentials and for all activities that occur under your account.</p>
          <p>d. Mahatel LLC reserves the right to suspend or terminate your account without prior notice if you violate these Terms and Conditions or engage in any fraudulent activities.</p>
          <h6><strong>Purchase and Activation of eSIM Profiles</strong></h6>
          <p>a. By purchasing an eSIM profile through the Website, you acknowledge and agree to abide by the terms and conditions set by mobile network operator.</p>
          <p>b. Mahatel LLC acts as a facilitator in providing eSIM services and is not responsible for the quality, availability, or performance of the underlying mobile network operator's services.</p>
          <p>c. The activation and usage of eSIM profiles are subject to the terms and conditions of eSIM aggregator and mobile network operators. You are responsible for understanding and complying with these terms.</p>
          <h6><strong>Intellectual Property</strong></h6>
          <p>a. The content and materials on the Website, including but not limited to text, graphics, images, logos, and trademarks, are the intellectual property of Mahatel LLC unless otherwise stated.</p>
          <p>b. You may not modify, reproduce, distribute, or commercially exploit any of the content without the explicit permission of Mahatel LLC.</p>
          <h6><strong>Limitation of Liability</strong></h6>
          <p>a. To the maximum extent permitted by law, Mahatel LLC shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or relating to the use or inability to use the eSIM services or the Website. This includes damages for errors, omissions, interruptions, defects, delays, computer viruses, loss of profits, or unauthorized access to and alteration of your transmissions and data.</p>
          <p>b. Mahatel LLC's liability, if any, shall be limited to the amount you paid for the eSIM services.</p>
          <h6><strong>Privacy and Data Protection</strong></h6>
          <p>a. Mahatel LLC is committed to protecting your privacy and handling your personal information in accordance with applicable laws and regulations. Please refer to our Privacy Policy for detailed information on how we collect, use, and protect your personal data.</p>
          <h6><strong>Governing Law and Jurisdiction</strong></h6>
          <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of Texas. Any disputes arising out of or in connection with these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts of Texas.</p>
          <p>By using the Website and purchasing eSIM services, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you have any questions or concerns regarding these Terms and Conditions, please contact Mahatel LLC.</p>
          <p>This document was last updated on {currentDate}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermsCondition
