import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, Redirect } from 'react-router-dom';
import authActions from '../../redux/auth/actions';
import { Form, Input, Button, message } from "antd";
import logo from '../../assets/image/virtuzo-logo.png';
import Loading from '../Loader/Loading';

function Login(props) {
    const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;
    const dispatch = useDispatch();
    const history = useHistory();
    const { loginRequest, resetAuthError } = authActions;
    const successMessage = useSelector((state) => state.authReducer.message);
    const errorMessage = useSelector((state) => state.authReducer.error);
    const loading = useSelector((state) => state.authReducer.loading);
    const [redirectToReferrer, setRedirectToReferrer] = React.useState(false);
    const handleSignupActive = () => {
        history.push('/signup');
    }

    const handleSubmitLoginData = (values) => {
        dispatch(loginRequest(values, props.history));
    }

    const error = (text) => {
        const hide = message.error(
            //   <MessageContent>{text}</MessageContent>,
            <p style={{
                'display': 'inline-block',
                'fontSize': '15px'
            }}>{text}</p>,
            0
        );
        setTimeout(hide, 2000);
        dispatch(resetAuthError());
    };

    React.useEffect(() => {
        if (token) {
            setRedirectToReferrer(true);
        }
    }, [token]);

    if (redirectToReferrer) {
        return <Redirect to='/' />;
    }

    return (
        <section className="login-sec-1 login-bg">
            {/* alert message show */}
            {/* {successMessage !== null && successMessage !== undefined && successMsg(successMessage)} */}
            {errorMessage !== null && errorMessage !== undefined && error(errorMessage)}
            {loading && <Loading />}
            <div className="container">
                <div className="row d-flex align-item-center justify-content-center py-2 px-3">
                    <div className="col-lg-4 form-tab-1 py-3 ">
                        <Link to="/"><img src={logo} width="250px" className="py-3 mb-4" id='logo-fold-size'></img></Link>
                        {/* <h2 className="py-3"><a href="index.html">logo</a></h2> */}
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            {<li className="login-tab" role="presentation">
                                <button className="nav-link active" id="pills-home-tab1" data-bs-toggle="pill" data-bs-target="#pills-home1"
                                    type="button" role="tab" aria-controls="pills-home" aria-selected="true">Login</button>
                            </li>}
                            <li className="login-tab" role="presentation">
                                <button className="nav-link" id="pills-profile-tab1" data-bs-toggle="pill" data-bs-target="#pills-profile1"
                                    type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={handleSignupActive}>Sign Up</button>
                            </li>

                        </ul>
                        <div className="tab-content  d-flex align-item-center justify-content-center" id="pills-tabContent1">
                            <div className="tab-pane fade active show" id="pills-home1" role="tabpanel" aria-labelledby="pills-home-tab1">
                                <div className="login-title">
                                    <h4>Welcome To</h4>
                                    <h2>esim source</h2>
                                    <p className="para-sign">sign in to continue with your account</p>
                                </div>
                                <Form className="row-col" size="large" onFinish={handleSubmitLoginData}>
                                    <Form.Item className="username" name="username" rules={[{ required: true, message: "Please input your email!", }, { type: "email", message: "Please input a valid email!" }]}>
                                        <Input placeholder="Email Address" name="username" />
                                    </Form.Item>
                                    <Form.Item className="username" name="password" rules={[{ required: true, message: 'Please input your password!', },]}>
                                        <Input.Password placeholder="Password" name="password" />
                                    </Form.Item>
                                    <div className="mb-3 form-check">
                                        <Link to="/forget-password" style={{ color: '#f8921f', textDecoration: 'underline', fontSize: "16px" }}>Forget Password?</Link>
                                    </div>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" className='login-btn' style={{ width: "100%" }} > LOGIN </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login