import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import './assets/css/style.css';
import './assets/css/responsive.css';
import Main from "./layouts/Main";
import Home from "./components/Home/Home";
import Shop from "./components/Shop/Shop";
import Partner from "./components/Partner/Partner";
import Contact from "./components/Contact/Contact";
import PlanDetails from "./components/Plan/PlanDetails";
import CheckOut from "./components/Billing/CheckOut";
import Login from "./components/Auth/Login";
import Signup from "./components/Auth/Signup";
import Profile from "./components/Profile/Profile";
import Forget from "./components/Auth/Forget";
import ResetPassword from "./components/Auth/ResetPassword";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsCondition from "./components/Terms-and-Conditions/TermsCondition";
import RefundPolicies from "./components/Refund Policies/RefundPolicies";
import { useSelector } from 'react-redux';
import Error from "./components/Error/Error";
import { PathProvider } from "./components/PathProvider/PathProvider";
function App() {
  const [showSidebar, setShowSidebar] = React.useState(false);
  const internalServerError = useSelector((state) => state.homeReducer.error);
  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  const currentPath = PathProvider();

  React.useEffect(() => {
    const parts = pathname.split('/');
    if (pathname === '' || pathname === 'shop' || pathname === 'terms-condition' || pathname === 'refund-policies' || pathname === 'partner' || pathname === 'contact-us' || pathname === "profile" || parts[0] === 'check-out' || parts[0] === 'plan-details' || pathname === "privacy-policy") {
      setShowSidebar(true);

    } else {
      setShowSidebar(false);
    }
  }, [pathname]);

  return (
    <>{
      internalServerError ? <Error /> :
        <div className="App">
          <Switch>
            <Route path="/login" exact component={Login} />
            <Route path="/signup" exact component={Signup} />
            <Route path="/forget-password" exact component={Forget} />
            <Route path="/reset-password/:customerId/:token" exact component={ResetPassword} />
            {showSidebar && <Main>
              <Route exact path='/' component={Home}></Route>
              <Route exact path='/shop' component={Shop}></Route>
              <Route exact path='/partner' component={Partner}></Route>
              <Route exact path='/contact-us' component={Contact}></Route>
              <Route exact path='/plan-details/:referenceURL' component={PlanDetails}></Route>
              <Route exact path='/check-out/:referenceURl' component={CheckOut}></Route>
              <Route exact path='/profile' component={Profile}></Route>
              <Route exact path='/privacy-policy' component={PrivacyPolicy}></Route>
              <Route exact path='/terms-condition' component={TermsCondition}></Route>
              <Route exact path='/refund-policies' component={RefundPolicies}></Route>
            </Main>}
          </Switch>
        </div>
    }
    </>

  );
}

export default App;
