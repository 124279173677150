import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { postLogin, addData, getData, getList } from '../../helpers/fackBackend_Helper';

function* getSearchPlanResponse({ payload: { data } }) {
    try {
        const response = yield call(postLogin, '/searchData', data);
        if (response.response.Status) {
            yield put(
                actions.getSearchPlanSuccess(response.response.Country, response.response.Region));
        } else {
            yield put(actions.getSearchPlanFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getSearchPlanFailed(error));
    }
}

function* getvalidateTokenResponse() {
    try {
        const response = yield call(getList, '/validateToken');
        if (response.response.Status) {
            yield put(
                actions.validateTokenSuccess(response.response.Message));
        } else {
            yield put(actions.validateTokenFailed(response.response.Message));
        }
    } catch (error) {
        localStorage.removeItem("token");
        yield put(actions.validateTokenFailed(error));
    }
}

function* getPlanDataResponse({ payload: { filterData } }) {
    try {
        const response = yield call(postLogin, '/planForCustomer', filterData);
        if (response.response.Status) {
            yield put(
                actions.getPlanDataSuccess(response.response.Data));
        } else {
            yield put(actions.getPlanDataFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getPlanDataFailed(error));
    }
}

function* getPlanDataBestSellerResponse({ payload: { filterData } }) {
    try {
        const response = yield call(postLogin, '/bestSellerPlanForCustomer', filterData);
        if (response.response.Status) {
            yield put(
                actions.getPlanDataSuccess(response.response.Data));
        } else {
            yield put(actions.getPlanDataFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getPlanDataFailed(error));
    }
}

function* checkCompatibleStatusResponse({ payload: { data } }) {
    try {
        // const response = yield call(addData, '/imeiChecker', data);
        const response = yield call(addData, '/checkImei', data);
        if (response.Status) {
            yield put(actions.checkCompatibleStatusSuccess(
                response.Message, response.IMEI, response.Response
            ));
        } else {
            yield put(actions.checkCompatibleStatusFailed(
                response.response.Message, response.response.IMEI
            ));
        }
    } catch (error) {
        yield put(actions.checkCompatibleStatusFailed(error));
    }
}

function* getPlanDetailsResponse({ payload: { referenceURL } }) {
    try {
        const response = yield call(getData, '/getPlanByReferenceURl/' + referenceURL);
        if (response.response.Status) {
            yield put(
                actions.getPlanByReferenceURLSuccess(response.response.Data));
        } else {
            yield put(actions.getPlanByReferenceURLFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getPlanByReferenceURLFailed(error));
    }
}

function* getBannerDataResponse() {
    try {
        const response = yield call(getList, '/banner');
        if (response.response.status = 1) {
            yield put(
                actions.getBannerSuccess(
                    response.response.Data
                )
            );
        } else {
            yield put(actions.getBannerFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getBannerFailed(error.Message));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_SEARCH_PLAN, getSearchPlanResponse)]);
    yield all([takeEvery(actions.GET_PLAN_DATA, getPlanDataResponse)]);
    yield all([takeEvery(actions.GET_PLAN_DATA_BEST_SELLER, getPlanDataBestSellerResponse)]);
    yield all([takeEvery(actions.CHECK_COMPATIBLE_STATUS, checkCompatibleStatusResponse)]);
    yield all([takeEvery(actions.GET_PLAN_BY_REFERENCE_URL, getPlanDetailsResponse)]);
    yield all([takeEvery(actions.VALIDATE_TOKEN, getvalidateTokenResponse)]);
    yield all([takeEvery(actions.GET_BANNER_DATA, getBannerDataResponse)]);
}