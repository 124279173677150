import React from 'react';
import { Link } from 'react-router-dom';
import logo1 from '../assets/image/facebook (1).png';
import logo2 from '../assets/image/instagram.png';
import logo3 from '../assets/image/twitter.png';
import logo4 from '../assets/image/linkedin.png';
import logo from '../assets/image/logo-white.png';

function Footer() {
  let currentYear = new Date().getFullYear();
  return (
    <footer className="sec-5">
      <div className="container-flud">
        <div className="row pt-5 footer-set-m">
          <div className="col-lg-12 col-md-12 col-sm-12 footer-main">
            
              {/* <Link className="mb-3" to="/"><img src={logo} alt={logo} width="280px"></img></Link> */}
           
            <div className="col text-footer">
              <ul>
                <li><Link to="/terms-condition">Terms & Conditions</Link></li>
                <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                {/* <li><Link to="#">Terms of Service</Link></li> */}
                <li><Link to="/refund-policies">Refund Policy</Link></li>
                {/* <li><Link to="#">Support</Link></li> */}
              </ul>
            </div>
            <div className="col d-flex justify-content-center social-media-icons">
              <ul className="flex-icon">
                {/* <li className=""><a href="#"><img src={logo1} alt="" /></a></li> */}
                <li className=""><a href="https://www.instagram.com/esimsource" target='_blank'><img src={logo2} alt="" /></a></li>
                <li className=""><a href="https://twitter.com/EsimSource" target='_blank'><img src={logo3} alt="" /></a></li>
                <li className=""><a href="https://www.linkedin.com/in/esim-source-927930296/" target='_blank'><img src={logo4} alt="" /></a></li>
              </ul>
            </div>
            <div className="col">
              <p className="para-footer-all mb-3">All rights reserved by esim source @{currentYear} </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer